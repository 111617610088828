<template>
  <div
    class="listing-block-element">
    <div class="listing-block-element__content">
      <v-row
        v-masonry
        fit-width="true"
        transition-duration="0"
        stragger="0"
        horizontal-order="true"
        class="block-element">
        <slot name="createProductButton" />
        <BlockElement
          v-for="item in data"
          :id="item.id"
          :key="item.id"
          v-masonry-tile
          :is-shown="isShown"
          :item="item"
          :allow-intersect="allowIntersect"
          :data-listing-to-show="dataListingToShow"
          v-on="$listeners">
          <template
            v-for="(_, name) in $scopedSlots"
            v-slot:[name]="props">
            <slot
              :name="name"
              v-bind="props" />
          </template>
        </BlockElement>
        <template v-if="showLoadingElements">
          <AppListingItemSkeleton
            v-for="n in loadingElementsCount"
            :key="n" />
        </template>
      </v-row>
    </div>
  </div>
</template>
<script>
import AppListingItemSkeleton from '@/components/App/AppSkeleton/AppListingItemSkeleton';
import BlockElement from './BlockElement';
export default {
  name: 'AppListingBlockElement',
  components: {
    AppListingItemSkeleton,
    BlockElement,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    dataListingToShow: {
      type: Array,
      default: () => [],
    },
    allowIntersect: {
      type: Boolean,
      default: false,
    },
    isShown: {
      type: Boolean,
      default: false,
    },
    showLoadingElements: {
      type: Boolean,
      default: false,
    },
    loadingElementsCount: {
      type: Number,
      default: 3,
    },
  },
};
</script>
<style lang="scss">
  .listing-block-element {
    display: grid;
    gap: 15px;
    padding: 20px 40px;
    &__content {
      display: grid;
      justify-content: center;
    }
  }
</style>
