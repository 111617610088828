<template>
  <div
    class="all-comments"
    style="grid-template-columns: initial;">
    <div
      class="general-comments">
      <div class="cards">
        <CommentsCard
          ref="projectComments"
          hide-resolved-toggle
          :resource-id="resourceId"
          :comments-modal="generalCommentsModal" />
      </div>
    </div>
  </div>
</template>
<script>
import AppCommentsCard from '@/components/App/AppComments/AppCommentsCard';
export default {
  name: 'ModalBody',
  components: {
    CommentsCard: AppCommentsCard,
  },
  props: {
    generalCommentsModal: {
      type: Boolean,
      default: false,
    },
    resourceId: {
      type: String,
      required: true,
    },
  },
};
</script>
<style scoped lang="scss">
.v-expansion-panel-header {
  padding: 24px 48px 24px 24px;
}
.v-expansion-panel {
  overflow-y: auto;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  ::v-deep .v-expansion-panel-content__wrap {
    padding: 0 0 24px !important;
    max-height: 310px;
    overflow: auto;
    margin-right: 5px;
    .comments-wrapper {
      padding-top: 0 !important;
    }
  }
}
.buttons-wrapper .v-btn {
  font-family: 'Roboto', sans-serif;
  background: linear-gradient(270deg, #CF0072 -19.12%, #E9994A 102.38%);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
  border-width: 0;
}
::v-deep .v-btn__content {
  color: #fff !important;
}
::v-deep.v-badge {
  &__summary-comments {
    .v-badge__wrapper {
      top: 19px;
      left: -128px;
    }
  }
}
</style>
