var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table-container"},[_c('v-data-table',{ref:"v-data-table--schedule",staticClass:"v-data-table--schedule v-data-table--schedule__products",class:[
      ("v-data-table--schedule__" + _vm.selectedViewCriteria),
      _vm.imageOverlay
    ],attrs:{"headers":_vm.headers,"items":_vm.dataForTable,"item-key":"id","must-sort":"","hide-default-footer":"","hide-default-header":"","mobile-breakpoint":0,"fixed-header":"","disable-pagination":"","data-test":"collections_product_table","no-data-text":""},on:{"hook:mounted":_vm.onTableMounted},scopedSlots:_vm._u([{key:"header",fn:function(ref){
    var props = ref.props;
return [_c('table-header',{attrs:{"table-height":_vm.tableHeight,"headers":props.headers,"read-mode":_vm.readMode,"selected-all-control-ids":_vm.selectedAllControlIds},on:{"change-control-selects":function($event){return _vm.changeControlSelects({
          value: $event,
        })}}})]}},_vm._l((_vm.headers),function(header,index){return {key:("item." + (header.value)),fn:function(ref){
        var item = ref.item;
return [(header.value === 'order')?[_c('ProductCellOrder',{key:((header.value) + "___order-view"),attrs:{"order":item.order},scopedSlots:_vm._u([(_vm.showCollectionActions({
              id: item.id,
            }) && !_vm.isSharedCollection)?{key:"actions",fn:function(){return [_c('v-icon',{staticClass:"brightness-icon",attrs:{"medium":"","color":"mainGrey"},on:{"click":function($event){return _vm.setMenuItem({
                item: item,
                event: $event,
              })}}},[_vm._v(" mdi-dots-vertical ")])]},proxy:true}:null],null,true)}),(!_vm.readMode)?_c('ProductCellExpandedView',{key:((header.value) + "___expand-view"),on:{"showExpandedDialog":function($event){return _vm.onShowExpandedDialog(item, _vm.isCommunityCollections)}}}):_vm._e(),(!_vm.readMode)?_c('ControlSelects',{key:((header.value) + "___selectRow"),staticClass:"pb-3",attrs:{"data-test":"product_checkbox","value":_vm.selectedControl(item.id)},on:{"change":function($event){return _vm.changeControlSelects({
            id: item.id,
          })}}}):_vm._e()]:(_vm.shouldShowProductCell({ colIndex: index, item: item }))?_c('ProductCell',{key:((header.column.id) + "__" + index),style:({ justifyContent: header.align === 'right' ? 'flex-end' : 'inherit' }),attrs:{"id":header.column.id,"is-expanded-mode":false,"header":header,"item":item,"collection-id":_vm.collectionId,"allow-show-select-col":"","bordered-cell":_vm.borderedCell({
          currentRowId: item.id,
          columnName: header.value,
          columnType: header.column.type,
          headerId: header.id,
        }),"is-opened-in-dialog":false,"disabled-edit-mode":_vm.disabledEditMode || item.skeleton,"read-mode":_vm.readMode,"data":_vm.sor.wrap(_vm.data, 'tableCellData')},on:{"updateProductField":_vm.updateProductField}}):(_vm.projectsTableOptimization)?_c('AppTableCellSkeleton',{key:((header.column.id) + "__" + index)}):_vm._e()]}}})],null,true)}),(_vm.selectedItem)?_c('TableActions',{attrs:{"is-store-product-preload":_vm.isStoreProductPreload,"show-activator":false,"item":_vm.selectedItem,"position-x":_vm.positionX,"position-y":_vm.positionY,"value":_vm.showActionsCard},on:{"update:item":function($event){_vm.selectedItem=$event},"update:value":function($event){_vm.showActionsCard=$event}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }