<template>
  <div>
    <v-row class="mx-sm-6 mx-4  mb-0 mt-1 align-center">
      <v-col
        class="pb-0"
        cols="10">
        <v-text-field
          v-if="!multiline"
          :value="url"
          class="url"
          type="text"
          readonly
          filled
          rounded
          dense>
          <template v-slot:append>
            <slot name="append-icon" />
          </template>
        </v-text-field>
        <div
          v-else
          class="shared-link__multiline">
          {{ getEmbedLink }}
        </div>
      </v-col>
      <v-col
        class="pb-0 pl-0"
        cols="2">
        <span
          data-test="copy_share_collection_link_button"
          class="color-link body-1"
          @click="copyToClipBoard(getEmbedLink, textCopied)">
          {{ copyText }}
        </span>
      </v-col>
    </v-row>
    <template v-if="isAllowSeeEmbedLink">
      <v-row class="mx-sm-10 mx-4 mb-0 mt-0 align-center mt-2">
        <v-col cols="6">
          <v-row class="align-center">
            <size-definer
              v-model="width"
              description="Recommended values for the embed window width are 100% or 700 px and higher"
              label="Width" />
          </v-row>
        </v-col>
      </v-row>
      <v-row class="mx-sm-10 mx-4 mb-0 mt-0 align-center mt-2">
        <v-col cols="6">
          <v-row class="align-center">
            <size-definer
              v-model="height"
              description="Recommended values for the embed window height are 100% or 700 px and higher"
              label="Height" />
          </v-row>
        </v-col>
      </v-row>
    </template>
    <v-row class="mx-sm-6 mx-4 mb-0">
      <v-col cols="10 pt-0 pb-0">
        <p class="info-link caption">
          <slot name="bottom-text" />
        </p>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { copyToClipBoard } from '@/utils';
import { mapGetters } from 'vuex';
import SizeDefiner from '@/components/App/AppSharedLink/SizeFields/SizeDefiner.vue';
export default {
  name: 'InputSharedLink',
  components: {
    SizeDefiner,
  },
  props: {
    textCopied: {
      type: String,
      default: 'Link copied',
    },
    url: {
      type: String,
      required: true,
    },
    multiline: {
      type: Boolean,
      default: false,
    },
    linesToShow: {
      type: Number,
      default: 4,
    },
    copyText: {
      type: String,
      default: 'Copy link',
    },
    searchParams: {
      type: String,
      default: '',
    },
    isCollectionEmbedCode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      width: {
        size: 100,
        unit: false,
      },
      height: {
        size: 100,
        unit: false,
      },
    };
  },
  computed: {
    ...mapGetters('FeatureFlags', [
      'isShowCollectionEmbedWindowSize',
    ]),
    isAllowSeeEmbedLink() {
      return this.isCollectionEmbedCode && this.isShowCollectionEmbedWindowSize;
    },
    getEmbedLink() {
      if (this.isAllowSeeEmbedLink) {
        const width = `${this.width.size}${this.width.unit ? 'px' : '%'}`;
        const height = `${this.height.size}${this.height.unit ? 'px' : '%'}`;
        return `
        <embed
          src="https://${window.location.hostname}/collection/${this.$route.params.id}/embed?${this.searchParams}"
          width="${width}"
          height="${height}">
        `;
      }
      return this.url;
    },
  },
  methods: {
    copyToClipBoard,
  },
};
</script>
<style lang="scss" scoped>
::v-deep .url.url:not(.v-autocomplete, .v-dropdown__custom) > .v-input__control > .v-input__slot {
  background: var(--v-grey-base) !important;
}
.info-link.caption{
  margin: 0 !important;
}
::v-deep .v-text-field--rounded > .v-input__control > .v-input__slot{
  padding-right: 10px;
}
.shared-link__multiline {
  height: auto;
  padding: 12px;
  background: var(--v-grey-base) !important;
  word-break: break-all;
}
</style>
