<template>
  <v-badge
    :content="totalUnreadComments"
    class="pointer v-badge__summary-comments"
    color="mainBlue"
    :value="totalUnreadComments > 0">
    <div class="mt-2">
      <v-btn
        outlined
        class="select-view-container__edit-button mr-2 customButtonStyle">
        <v-icon
          size="20"
          color="#fff">
          mdi-comment-multiple-outline
        </v-icon>
        {{ btnName }}
      </v-btn>
    </div>
  </v-badge>
</template>
<script>
export default {
  name: 'AppSummaryCommentsBtn',
  props: {
    totalUnreadComments: {
      type: Number,
      default: 0,
    },
    btnName: {
      type: String,
      default: 'Collection Chat',
    },
  },
};
</script>
<style lang="scss">
.v-badge {
  &__summary-comments {
    .v-badge__wrapper {
      top: 19px;
      left: -158px;
    }
  }
}
</style>
