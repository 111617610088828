<template>
  <CollectionDetails
    is-store-product-preload />
</template>
<script>
import CollectionDetails from '@/components/CollectionDetails';
import { getFullLinkForWorkspaces } from '@/utils';
import {
  mapGetters, mapMutations,
} from 'vuex';
export default {
  name: 'MyCollectionDetails',
  components: {
    CollectionDetails,
  },
  computed: {
    ...mapGetters('Libraries', ['getDefaultLibraryId']),
    renderActiveTab() {
      if (this.$route.params.libraryId) {
        return getFullLinkForWorkspaces('libraries');
      }
      return getFullLinkForWorkspaces(`collections/${this.getDefaultLibraryId}`);
    },
  },
  destroyed() {
    this.setAccessToken(null);
  },
  methods: {
    ...mapMutations(['setAccessToken']),
  },
};
</script>
<style scoped lang="scss">
</style>
