<template>
  <div
    class="px-0">
    <app-action-btn
      :access="canWorkWithEmbedLink"
      show-tooltip
      bottom
      class="cursor-pointer"
      wrapper-class="medium-p"
      @click="openDialog">
      <template #iconWrapper>
        <v-icon size="15">
          mdi-xml
        </v-icon>
      </template>
      Collection Embed Code
    </app-action-btn>
  </div>
</template>
<script>
import {
  mapGetters, mapState, mapMutations,
} from 'vuex';
export default {
  name: 'CollectionEmbedCode',
  computed: {
    ...mapState('Collections', ['embeddedDialog']),
    ...mapGetters('UserRoles', ['usersRolesListInCollection', 'canCreateEmbedLink']),
    canWorkWithEmbedLink() {
      const role = this.usersRolesListInCollection;
      return this.canCreateEmbedLink(role);
    },
  },
  methods: {
    ...mapMutations('Collections', ['setEmbeddedDialog']),
    openDialog() {
      if (this.canWorkWithEmbedLink) {
        this.setEmbeddedDialog(true);
      }
    },
  },
};
</script>
