<template>
  <div
    v-intersect.once="onIntersect"
    class="block-element__content"
    :class="{
      'block-element__content__search-hightlighting__selected': item.isSelectedCellSearchHighlighting,
      'block-element__content__search-hightlighting': item.isCellSearchHighlighting,
    }"
    @click="openWindow">
    <slot
      :id="item.id"
      name="badge" />
    <div class="verified-check">
      <AppVerifiedMark
        :is-verified="isVerified"
        :child-verified-ws="item.childVerified"
        :verified-page-id="item.verifiedPageId"
        :verified-page-name="item.verifiedPageName" />
    </div>
    <div
      v-if="imageArrayLength"
      class="block-element__content__image-item">
      <ElementImage
        :is-shown="isShown"
        :height-of-image="imageHeight"
        :items="imageArray" />
    </div>
    <v-icon
      v-else
      size="160"
      color="#67BED3">
      mdi-image
    </v-icon>
    <ElementsContainer
      :data-listing-to-show="dataListingToShow"
      :item="item" />
    <ElementProductType
      v-if="productTypeItems.length"
      :types="productTypeItems" />
    <slot
      :item="item"
      name="bottomAction" />
  </div>
</template>
<script>
import ElementImage from './ElementImage';
import ElementsContainer from './ElementsContainer';
import ElementProductType from './ElementProductType';
import productHeaders from '@/constants/productHeaders';
import AppVerifiedMark from '@/components/App/AppVerifiedMark';
export default {
  name: 'BlockElement',
  components: {
    AppVerifiedMark,
    ElementImage,
    ElementsContainer,
    ElementProductType,
  },
  props: {
    dataListingToShow: {
      type: Array,
      default: () => [],
    },
    item: {
      type: Object,
      default: () => {
      },
    },
    allowIntersect: {
      type: Boolean,
      default: false,
    },
    isShown: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      productHeaders,
    };
  },
  computed: {
    productTypeItems() {
      const { [productHeaders.PRODUCT_TYPE]: items = [] } = this.item ?? {
      };
      if (!Array.isArray(items) || !items) return [];
      return items;
    },
    imageArray() {
      return this.item['Image'] || [];
    },
    imageArrayLength() {
      return this.imageArray.length;
    },
    imageHeight() {
      const itemUnit = this.imageArray[0];
      if (itemUnit?.thumbnails?.large?.width) {
        const widthPersentage = (222 * 100) / itemUnit?.thumbnails?.large?.width;
        const heightPersentage = (widthPersentage * itemUnit?.thumbnails?.large?.height) / 100;
        return `height: ${heightPersentage}px`;
      }
      return 'height: auto';
    },
    isVerified() {
      return this.item?.isVerified;
    },
  },
  methods: {
    openWindow() {
      this.$emit('openUpdateWindow', this.item);
    },
    onIntersect(entries, observer, isIntersecting) {
      if (!this.allowIntersect) {
        return;
      }
      this.$emit('onIntersect', {
        entries, observer, isIntersecting,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.block-element {
  &__content {
    &__search-hightlighting {
      background: #e9d8094f;
      &__selected {
        background: #e9d8099e;
      }
    }
    width: 222px;
    gap: 10px;
    overflow-wrap: anywhere;
    align-content: start;
    margin: 15px 7px;
    position: relative;
    cursor: pointer;
    display: grid;
    &__image-item {
      width: 100%;
      overflow: hidden;
    }
  }
}
.verified-check {
  position: absolute;
  z-index: 4;
  top: 0;
  right: 0;
  transform: translate(25%, -25%);
}
</style>
