<template>
  <v-menu
    offset-y
    nudge-bottom="10"
    nudge-left="100"
    content-class="edit-menu">
    <template #activator="{ on }">
      <span
        style="width: 16px; height: 16px;">
        <v-icon
          v-show="comment.commentId === hoverRowId
            && userInfo.email === comment.creator.email"
          small
          class="pl-2 pointer"
          v-on="on">
          mdi-chevron-down
        </v-icon>
      </span>
    </template>
    <v-card class="nav-card">
      <p
        @click="$emit('deleteComment')">
        <img
          src="@/assets/icons/delete-icon.svg">
        Delete
      </p>
      <p
        v-if="comment.commentType !== 'attachment'"
        @click="$emit('editComment')">
        <img
          style="margin-left: 2px"
          src="@/assets/icons/rename-icon.svg">
        Edit
      </p>
    </v-card>
  </v-menu>
</template>
<script>
import { mapState } from 'vuex';
export default {
  name: 'AppCommentsActions',
  props: {
    comment: {
      type: Object,
      default: () => null,
    },
    hoverRowId: {
      type: String,
      default: null,
    },
  },
  computed: {
    ...mapState(['userInfo']),
  },
};
</script>
