<template>
  <div
    class="followers-amount__wrapper">
    <v-tooltip bottom>
      <template #activator="{ on }">
        <div class="followers-amount__tooltip">
          <span
            :class="[renderedFollowersAmountClass]"
            class="followers-amount__tooltip-text px-0">
            <div
              v-on="on">
              <v-icon
                color="white"
                size="18">
                mdi-account-outline
              </v-icon>
              <span>{{ renderedFollowersAmount }}</span>
            </div>
          </span>
        </div>
      </template>
      <span>Followers</span>
    </v-tooltip>
  </div>
</template>
<script>
import followersAmount from '@/mixins/FollowersAmount';
export default {
  name: 'AppFollowersAmount',
  mixins: [followersAmount],
  computed: {
    renderedFollowersAmountClass() {
      if (this.renderedFollowersAmount > 9) {
        return 'followers-amount__tooltip-text-medium';
      }
      if (this.renderedFollowersAmount < 9) {
        return 'followers-amount__tooltip-text-small';
      }
      return '';
    },
  },
};
</script>
<style scoped lang="scss">
  .followers-amount {
    &__wrapper {
      position: relative;
      width: 56px;
    }
    &__tooltip {
      position: relative;
      display: inline-block;
      border-bottom: 1px dotted var(--v-greyDarken-base);
      height: 28px;
      &-text {
        width: 56px;
        height: 36px;
        background-color: var(--v-greyDarken-base);
        color: #fff !important;
        text-align: center;
        border-radius: 6px;
        padding-top: 8px;
        padding-bottom: 5px;
        /* Position the tooltip text - see examples below! */
        position: absolute;
        z-index: 1;
        &-small {
          width: 42px;
        }
        &-medium {
          width: 48px;
        }
        span {
          color: white !important;
          font-size: 13px !important;
        }
        &::after {
          content: "";
          position: absolute;
          top: 100%; /* At the bottom of the tooltip */
          left: 50%;
          margin-left: -9px;
          border-width: 9px;
          border-style: solid;
          border-color: var(--v-greyDarken-base) transparent transparent transparent;
        }
      }
    }
  }
</style>
