<template>
  <div class="carousel mt-5">
    <v-carousel
      v-if="images.length"
      hide-delimiter-background
      show-arrows-on-hover
      delimiter-icon="mdi-minus">
      <template v-for="(item, index) in images">
        <v-carousel-item
          v-if="(index + 1) % columns === 1 || columns === 1"
          :key="index">
          <v-row
            class="flex-nowrap">
            <template
              v-for="(n,i) in columns">
              <template
                v-if="(index + i) < images.length">
                <v-col
                  :key="i">
                  <v-sheet
                    v-if="(index + i) < images.length">
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <img
                        :src="images[index + i].url"
                        alt="gallery"
                        style="max-width:100%; border-radius: 20px;"
                        @error="$emit('error', images[index + i])"
                        @click="showFullSizeCarousel({
                          index: index + i,
                        })">
                    </v-row>
                  </v-sheet>
                </v-col>
              </template>
            </template>
          </v-row>
        </v-carousel-item>
      </template>
    </v-carousel>
  </div>
</template>
<script>
import { mapMutations } from 'vuex';
export default {
  name: 'CollectionsPhotos',
  components: {
  },
  props: {
    images: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      filesForPreview: [],
      startingImageIndex: 0,
    };
  },
  computed: {
    columns() {
      if (this.$vuetify.breakpoint.xl) {
        return 4;
      }
      if (this.$vuetify.breakpoint.lg) {
        return 3;
      }
      if (this.$vuetify.breakpoint.md) {
        return 2;
      }
      return 1;
    },
  },
  methods: {
    ...mapMutations(['setFilesForPreview', 'setFilesForPreviewIndex']),
    showFullSizeCarousel({ index = 0 } = {
    }) {
      this.setFilesForPreview(this.images);
      this.setFilesForPreviewIndex(index);
    },
  },
};
</script>
<style lang="scss">
.carousel {
  width: 100%;
  margin: auto;
  margin-bottom: 20px;
  height: auto;
  .v-window {
    height: 250px !important;
    border-radius: 20px;
    &__container {
      .v-window-item {
        .v-responsive {
          height: 250px !important;
          &__content {
            .row {
              margin: 0px;
              gap: 30px;
              height: 100%;
              .col {
                padding: 0;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                img {
                  cursor: pointer;
                  transition: 1s;
                }
                img:hover {
                  transform: scale(1.1);
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
