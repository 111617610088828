var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"comments-wrapper pt-6"},[(_vm.data.nextToken)?_c('p',{staticClass:"medium-p mb-0 mx-8 mb-2 text-center show-more-comments",on:{"click":function($event){return _vm.$emit('getComments', {
      scanIndexForward: false,
    })}}},[_c('span',{staticClass:"pointer"},[_vm._v(" Show Earlier Comments ")])]):_vm._e(),_vm._l((_vm.filterComments),function(comment,index){return _c('v-row',{key:comment.commentId,ref:"commentsRows",refInFor:true,staticClass:"comments-wrapper-border-items",class:{ 'mb-0' : index === _vm.data.comments.length -1,
              'comments-wrapper-border-items__edited': _vm.editedRowId === comment.commentId,
              'comments-wrapper-border-items__scrolled-comment': _vm.scrolledComment === comment.commentId,
    },attrs:{"id":("commentId_" + (comment.commentId)),"data-test":"project_comment_block"},on:{"mouseover":function($event){!_vm.$route.query.version ? _vm.hoverRowId=comment.commentId : _vm.hoverRowId=null},"mouseleave":function($event){_vm.hoverRowId=null}}},[_c('div',[_c('AvatarImage',{attrs:{"avatar":_vm.avatars[_vm.getCreatorProp({ comment: comment })],"avatar-key":_vm.getCreatorProp({ comment: comment }),"color":_vm.getCreatorColor({ comment: comment }),"size":40}})],1),_c('v-col',{staticClass:"pa-0",class:{'pl-3': _vm.$vuetify.breakpoint.mdAndUp },attrs:{"sm":"11","cols":"12"}},[_c('v-row',{staticClass:"ma-0 d-flex justify-space-between align-center"},[_c('UserInfo',{attrs:{"user-info":comment.creator},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var teamRole = ref.userProjectTeamRole;
            var userNameTitle = ref.userNameTitleAccordingToRole;
return [_c('p',{staticClass:"medium-p font-weight-medium mb-0",staticStyle:{"overflow-wrap":"anywhere"},attrs:{"data-test":"project_comment_author_name"}},[_vm._v(" "+_vm._s(userNameTitle)+" "),(teamRole)?_c('span',{staticClass:"subtitle-2"},[_vm._v(" "+_vm._s(teamRole)+" ")]):_vm._e()])]}}],null,true)}),_c('p',{staticClass:"small-p title-in-modal mb-0 d-flex",attrs:{"data-test":"project_comments_time"}},[_vm._v(" "+_vm._s(_vm.setDateOfComment(comment))+" "+_vm._s(_vm.showCommentEditedTitle(comment))+" "),(_vm.editMode && !_vm.isDisabledComments)?_c('Actions',{attrs:{"hover-row-id":_vm.hoverRowId,"comment":comment},on:{"editComment":function($event){return _vm.editComment(comment)},"deleteComment":function($event){return _vm.deleteComment(comment)}}}):_vm._e()],1)],1),_c('v-row',{staticClass:"ma-0 d-flex flex-column"},[_c('p',{staticClass:"small-p title-in-modal mb-3 ",class:{ 'unread-container' : _vm.commentsUnread(comment) },attrs:{"data-test":"project_comment_author_email"}},[_vm._v(" "+_vm._s(_vm.getCreatorProp({comment: comment, prop: 'email'}))+" "),_c('v-icon',{staticClass:"pl-2 pointer",attrs:{"size":"14"}},[_vm._v(" "+_vm._s(_vm.setCommentIcon(comment))+" ")]),(_vm.showCellPointerBlock(comment) && !_vm.isDisabledComments)?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"resolve-reveal-icon",attrs:{"data-test":"project_comment_resolve_button","size":_vm.isShowResolveAction(comment) ? 22 : 16},on:{"click":function($event){return _vm.manageCellCommentResolved(comment)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.setResolveIcon(comment))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.setResolveTooltip(comment)))])]),(!_vm.isShowResolveAction(comment))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"resolve-reveal-icon",attrs:{"medium":""},on:{"click":function($event){return _vm.manageRevealing(comment)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.setExpandedIcon(comment.id))+" ")])]}}],null,true)},[_c('span',[_vm._v("Reveal")])]):_vm._e()]:_vm._e()],2)]),_c('v-row',{staticClass:"ma-0 flex-column comment-file"},[(comment.cellPointer)?_c('p',{staticClass:"small-p title-in-modal"},[_vm._v(" Related to "),_c('strong',[_vm._v(_vm._s(comment.cellPointer))])]):_vm._e(),(comment.commentType !== 'attachment')?_c('p',{directives:[{name:"safe-html",rawName:"v-safe-html",value:(_vm.formattedTextareaValueForDiv(comment) || ''),expression:"formattedTextareaValueForDiv(comment) || ''"},{name:"linkified",rawName:"v-linkified"}],staticClass:"small-p mb-0",class:{
            'resolved-comment' : comment.commentResolved,
            'resolved-comment__expanded': _vm.expandedComments.includes(comment.id),
          },staticStyle:{"overflow-wrap":"anywhere"},attrs:{"data-test":"project_comment_text"}}):(comment.commentType === 'attachment')?_c('a',{staticClass:"comment-file-container",class:{
            'resolved-comment' : comment.commentResolved,
            'resolved-comment__expanded': _vm.expandedComments.includes(comment.commentId),
          },attrs:{"href":("" + (_vm.getFileUrlForDownload(comment) ? ("/getFile?fileId=" + (_vm.getFileUrlForDownload(comment))): '')),"target":"_blank"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-row',_vm._g(_vm._b({staticClass:"comment-file-container__content pointer",attrs:{"no-gutters":""}},'v-row',attrs,false),on),[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(!_vm.getThumbnailObjectKey(comment) || !_vm.getThumbnail(_vm.getThumbnailObjectKey(comment))),expression:"!getThumbnailObjectKey(comment) || !getThumbnail(getThumbnailObjectKey(comment))"}],attrs:{"src":require("@/assets/icons/file-icon.svg")}}),(!_vm.getThumbnailObjectKey(comment))?_c('p',{staticClass:"small-p mb-0 overflow-dots",staticStyle:{"max-width":"105px"}},[_vm._v(" "+_vm._s(_vm.getFileName(comment))+" ")]):_vm._e(),(_vm.getThumbnailObjectKey(comment))?_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:({
                      src: _vm.getThumbnail(_vm.getThumbnailObjectKey(comment)),
                      loading: require('@/assets/icons/file-icon.svg'),
                    }),expression:"{\n                      src: getThumbnail(getThumbnailObjectKey(comment)),\n                      loading: require('@/assets/icons/file-icon.svg'),\n                    }"}],staticClass:"img-screen thumbnail-img"}):_vm._e()]),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('p',{staticClass:"small-p download-p mb-0 d-flex",style:({
                      justifyContent: _vm.getThumbnailObjectKey(comment) ? 'flex-start' : 'initial',
                    })},[_c('a',{staticClass:"ml-0",attrs:{"href":("/getFile?fileId=" + (_vm.getFileUrlForDownload(comment)) + "&force_update=true")},on:{"click":function($event){$event.preventDefault();return _vm.downloadFile(comment)}}},[_vm._v(" Download ")])])])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.getFileName(comment))+" ")])])],1):_vm._e()])],1)],1)}),(_vm.data.nextTokenForUnread && !_vm.type)?_c('p',{staticClass:"medium-p mb-0 mt-2 mx-8 text-center show-more-comments",on:{"click":function($event){return _vm.$emit('getComments', {
      scanIndexForward: true,
    })}}},[_c('span',{staticClass:"pointer"},[_vm._v(" Show the Latest Comments ")])]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }