<template>
  <div class="d-flex justify-center flex-column align-center align-md-stretch flex-md-row collection-header-skeleton">
    <v-skeleton-loader
      class="collection-header-skeleton-image"
      type="image" />
    <div class="d-flex flex-column justify-space-between">
      <v-skeleton-loader
        class="mt-4 mt-md-0 d-flex justify-center justify-md-start"
        :width="300"
        type="heading" />
      <v-skeleton-loader
        class="b"
        type="list-item-three-line" />
      <v-skeleton-loader
        class="d-flex flex-row buttons-row mt-12 mt-md-0"
        type="button@3" />
    </div>
  </div>
</template>
<script>
export default {
  name: 'CollectionHeaderSkeleton',
};
</script>

<style scoped lang="scss">
   ::v-deep .v-skeleton-loader.v-skeleton-loader--is-loading {
    .v-skeleton-loader__image {
      height: 100%;
    }
    .v-skeleton-loader__heading {
      height: 2rem;
    }
    .v-skeleton-loader__paragraph {
      margin-left: -12px;
    }
    .v-skeleton-loader__button {
      width: 85px;
      height: 32px;
      border-radius: 20px;
    }
  }
  .collection-header-skeleton {
    gap: 30px;
  }
  .collection-header-skeleton-image {
    width: 218px;
    height: 144px;
    border-radius: 20px;
  }
  .buttons-row {
    column-gap: 16px;
  }
</style>