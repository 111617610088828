<template>
  <div>
    <app-dialog
      v-model="isModalOpen"
      :value.sync="isModalOpen"
      width="514"
      content-class="v-dialog__form attachments-documents-wrapper">
      <content-download-document
        :images="images"
        :use-new-pdf-library="useNewPdfLibrary"
        :can-work-with-project-documents="canWorkWithProjectDocuments"
        v-on="$listeners"
        @closeDialog="closeDialog" />
    </app-dialog>
    <app-icon-btn
      show-tooltip
      bottom
      icon-name="mdi-camera-burst"
      icon-color="000"
      icon-height="25"
      wrapper-class="collections-photos-icon"
      :access="canWorkWithProjectDocuments"
      btn-class="mr-2"
      @click="canWorkWithProjectDocuments.allowed && (isModalOpen = true)">
      <p class="medium-p">
        Add photos
      </p>
    </app-icon-btn>
  </div>
</template>
<script>
import {
  mapGetters, mapState,
} from 'vuex';
import ContentDownloadDocument from '@/components/contentDownloadDocument';
export default {
  name: 'CollectionPhotos',
  components: {
    ContentDownloadDocument,
  },
  props: {
    images: {
      type: Array,
      default: () => [],
    },
  },
  data: function () {
    return {
      isModalOpen: false,
    };
  },
  computed: {
    ...mapState({
      activeHeader: 'activeHeader',
    }),
    ...mapGetters('UserRoles', ['canModifyCarouselPhotos', 'findUserRoleInLibraryForActiveHeader', 'usersRolesListInCollection']),
    ...mapGetters('FeatureFlags', ['useNewPdfLibrary']),
    canWorkWithProjectDocuments() {
      const { members } = this.activeHeader || {
      };
      return this.canModifyCarouselPhotos(members
        ? this.usersRolesListInCollection
        : this.findUserRoleInLibraryForActiveHeader
      );
    },
  },
  watch: {
    isModalOpen(val) {
      if (val) {
        this.$emit('getUrlForValues', this.$route.params.id);
      } else {
        this.$emit('clearAllItems');
      }
    },
  },
  methods: {
    closeDialog() {
      this.isModalOpen = false;
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .collections-photos-icon {
  display: flex;
  cursor: pointer;
  align-items: center;
  p {
    margin-bottom: 0px !important;
  }
}
.v-card__text {
  max-height: 300px;
  overflow: auto;
}
</style>
