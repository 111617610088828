<template>
  <app-dialog
    v-model="embedDialog"
    :value.sync="embedDialog"
    width="650px"
    persistent
    content-class="v-dialog__form"
    @keydown.esc="setEmbeddedDialog(false) ">
    <div class="dialog-content">
      <v-card>
        <header-share-advanced
          icon-name="mdi-xml"
          theme-color="dark-theme"
          @closeModal="setEmbeddedDialog(false)">
          <template #textTitle>
            Collection Embed Code
          </template>
          <template #subtitle>
            Integrate collections into your webpages
          </template>
        </header-share-advanced>
        <input-shared-link
          multiline
          text-copied="Embed Link copied for sending"
          copy-text="Copy code"
          :url="embedLink"
          :search-params="searchParams.toString()"
          is-collection-embed-code
          class="mt-3">
          <template #bottom-text>
            Collection Embed Code makes it possible to integrate Sourcery collection data into your
            website in just a few clicks
          </template>
        </input-shared-link>
        <v-card-text class="v-card__embed-link">
          <v-checkbox
            v-for="({ name, value, disabled, text }) in paramsToShow"
            :key="name"
            :input-value="value"
            :disabled="disabled"
            hide-details
            :label="text"
            @change="!disabled && setItemToStorage(name, $event)" />
          <v-card-actions
            class="d-flex justify-end pb-0">
            <template>
              <v-btn
                outlined
                color="lightBlue"
                @click="setEmbeddedDialog(false)">
                Cancel
              </v-btn>
            </template>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </div>
  </app-dialog>
</template>
<script>
import InputSharedLink from '@/components/App/AppSharedLink';
import HeaderShareAdvanced from '@/components/CollectionsLibrarysRelocate/HeaderShareAdvanced';
import { NOT_PERMITTED_ACCESS } from '@/constants/userPermissions';
import {
  mapState, mapMutations,
} from 'vuex';
import {
  COLLECTION_NAME, COLLECTION_PHOTOS, COLLECTION_PRODUCTS,
  EMBED_HEIGHT, EMBED_WIDTH,
} from '@/constants/embedLinkParams';
import { isEmpty } from 'lodash';
import CollectionsApi from '@/services/graphql/collections';
export default {
  name: 'CollectionEmbedCodeDialog',
  components: {
    InputSharedLink,
    HeaderShareAdvanced,
  },
  data() {
    return {
      fieldsToShow: [],
      selectedFieldsToShow: '',
      mode: '',
      requiredFields: [],
      NOT_PERMITTED_ACCESS,
      accessToken: '',
      paramsToShow: [
        {
          name: COLLECTION_NAME,
          text: 'Collection Name',
          value: false,
          disabled: false,
        },
        {
          name: COLLECTION_PHOTOS,
          text: 'Collection Photos',
          value: false,
          disabled: false,
        },
        {
          name: COLLECTION_PRODUCTS,
          text: 'Collection Products',
          value: true,
          disabled: true,
        },
      ],
    };
  },
  computed: {
    ...mapState('Workspace', ['activeWorkspaceId']),
    ...mapState('Collections', ['embeddedDialog']),
    embedDialog: {
      get() {
        return this.embeddedDialog;
      },
      set(value) {
        this.setEmbeddedDialog(value);
      },
    },
    searchParams() {
      const urlParams = new URLSearchParams();
      const { accessToken } = this;
      urlParams.set('accessToken', accessToken);
      this.paramsToShow.forEach(param => {
        if (param.value) {
          urlParams.set(param.name, param.value);
        }
      });
      return urlParams;
    },
    embedLink() {
      return `
        <embed
           src="https://${window.location.hostname}/collection/${this.$route.params.id}/embed?${this.searchParams.toString()}"
           width="${EMBED_WIDTH}"
           height="${EMBED_HEIGHT}">
      `;
    },
    storageKey() {
      return `embedCodeSettings__${this.$route.params.id}`;
    },
  },
  watch: {
    async embeddedDialog(val) {
      if (val) {
        if (!isEmpty(this.getCheckboxValueFromStorage())) {
          this.paramsToShow = this.getCheckboxValueFromStorage();
        }
        const workspaceId = this.activeWorkspaceId;
        const { data } = await CollectionsApi.getCollectionSharedLink({
          collectionId: this.$route.params.id,
          workspaceId,
        });
        this.accessToken = data.response?.accessToken;
      }
    },
  },
  methods: {
    ...mapMutations('Collections', ['setEmbeddedDialog']),
    getCheckboxValueFromStorage() {
      const item = window.localStorage.getItem(this.storageKey);
      if (item) {
        return JSON.parse(item);
      }
      return {
      };
    },
    setItemToStorage(item, value) {
      const items = this.paramsToShow.map(param => param.name == item ? {
        ...param,
        value,
      } : param);
      this.paramsToShow = items;
      window.localStorage.setItem(this.storageKey, JSON.stringify(items));
    },
  },
};
</script>
<style scoped lang="scss">
.v-card__embed-link {
  padding-top: 0 !important;
}
</style>
