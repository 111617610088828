<template>
  <div
    class="table-container">
    <v-data-table
      ref="v-data-table--schedule"
      class="v-data-table--schedule v-data-table--schedule__products"
      :class="[
        `v-data-table--schedule__${selectedViewCriteria}`,
        imageOverlay
      ]"
      :headers="headers"
      :items="dataForTable"
      item-key="id"
      must-sort
      hide-default-footer
      hide-default-header
      :mobile-breakpoint="0"
      fixed-header
      disable-pagination
      data-test="collections_product_table"
      no-data-text=""
      @hook:mounted="onTableMounted">
      <template v-slot:header="{ props }">
        <table-header
          :table-height="tableHeight"
          :headers="props.headers"
          :read-mode="readMode"
          :selected-all-control-ids="selectedAllControlIds"
          @change-control-selects="changeControlSelects({
            value: $event,
          })" />
      </template>
      <template
        v-for="(header, index) in headers"
        #[`item.${header.value}`]="{ item }">
        <template v-if="header.value === 'order'">
          <ProductCellOrder
            :key="`${header.value}___order-view`"
            :order="item.order">
            <template
              v-if="showCollectionActions({
                id: item.id,
              }) && !isSharedCollection"
              #actions>
              <v-icon
                medium
                color="mainGrey"
                class="brightness-icon"
                @click="setMenuItem({
                  item,
                  event: $event,
                })">
                mdi-dots-vertical
              </v-icon>
            </template>
          </ProductCellOrder>
          <ProductCellExpandedView
            v-if="!readMode"
            :key="`${header.value}___expand-view`"
            @showExpandedDialog="onShowExpandedDialog(item, isCommunityCollections)" />
          <ControlSelects
            v-if="!readMode"
            :key="`${header.value}___selectRow`"
            data-test="product_checkbox"
            class="pb-3"
            :value="selectedControl(item.id)"
            @change="changeControlSelects({
              id: item.id,
            })" />
        </template>
        <ProductCell
          v-else-if="shouldShowProductCell({ colIndex: index, item: item })"
          :id="header.column.id"
          :key="`${header.column.id}__${index}`"
          :is-expanded-mode="false"
          :header="header"
          :item="item"
          :collection-id="collectionId"
          allow-show-select-col
          :bordered-cell="borderedCell({
            currentRowId: item.id,
            columnName: header.value,
            columnType: header.column.type,
            headerId: header.id,
          })"
          :is-opened-in-dialog="false"
          :disabled-edit-mode="disabledEditMode || item.skeleton"
          :read-mode="readMode"
          :data="sor.wrap(data, 'tableCellData')"
          :style="{ justifyContent: header.align === 'right' ? 'flex-end' : 'inherit' }"
          @updateProductField="updateProductField" />
        <AppTableCellSkeleton
          v-else-if="projectsTableOptimization"
          :key="`${header.column.id}__${index}`" />
      </template>
    </v-data-table>
    <TableActions
      v-if="selectedItem"
      :is-store-product-preload="isStoreProductPreload"
      :show-activator="false"
      :item.sync="selectedItem"
      :position-x="positionX"
      :position-y="positionY"
      :value.sync="showActionsCard" />
  </div>
</template>
<script>
import {
  mapState, mapMutations, mapGetters, mapActions,
} from 'vuex';
import ProductCell from '@/components/Product/ProductCell';
import LoadingSpinnerReset from '@/mixins/LoadingSpinnerReset';
import ControlSelects from '@/components/Product/ProductCell/ProductCellControlSelects';
import ProductCellExpandedView from '@/components/Product/ProductCell/ProductCellExpandedView';
import updateCollectionProductField from '@/mixins/updateCollectionProductField';
import BorderedCollectionCell from '@/mixins/BorderedCollectionCell';
import DisableEditMode from '@/mixins/DisableEditMode';
import virtualScrollTable from '@/mixins/virtualScrollTable';
import ResizableMixin from '@/mixins/ResizableMixin';
import AppActionDotsMenu from '@/mixins/AppActionDotsMenu';
import ProductQuickLink from '@/mixins/ProductQuickLink';
import CollectionDetailsInfoHeader from '@/components/CollectionDetails/CollectionDetailsInfo/CollectionDetailsInfoHeader';
import AppTableCellSkeleton from '@/components/App/AppSkeleton/AppTable/AppTableCellSkeleton';
import {
  sortHelper, waitTimeout,
} from '@/utils';
import { SORTABLE_OPTIONS } from '@/constants/scheduleViews';
import 'vue-draggable-resizable/dist/VueDraggableResizable.css';
import { SelectiveObjectReuse } from 'selective-object-reuse';
import { watchBy } from '@/utils/vueHelpers';
export default {
  name: 'CollectionDetailsInfo',
  components: {
    ProductCell,
    ControlSelects,
    ProductCellExpandedView,
    ProductCellOrder: () => import('@/components/Product/ProductCell/ProductCellOrder'),
    TableActions: () => import('../CollectionActionsProduct'),
    TableHeader: CollectionDetailsInfoHeader,
    AppTableCellSkeleton,
  },
  mixins: [
    updateCollectionProductField,
    LoadingSpinnerReset,
    DisableEditMode,
    virtualScrollTable,
    BorderedCollectionCell,
    ResizableMixin,
    AppActionDotsMenu,
    ProductQuickLink,
  ],
  props: {
    isSharedCollection: {
      type: Boolean,
      default: false,
    },
    isShown: {
      type: Boolean,
      default: false,
    },
    isCommunityCollections: {
      type: Boolean,
      default: false,
    },
    headers: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Array,
      default: () => [],
    },
    isStoreProductPreload: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    SORTABLE_OPTIONS,
    sor: new SelectiveObjectReuse(),
  }),
  computed: {
    ...mapState(['imagePreviewOverlay', 'activeHeader']),
    ...mapState('Collections', ['selectedControlProductsIds', 'collectionsSchema']),
    selectedControlIds: {
      get() {
        return this.selectedControlProductsIds;
      },
      set(val) {
        this.setSelectedControlProductsIds(val);
      },
    },
    selectedAllControlIds() {
      const { selectedControlIds, data } = this;
      return selectedControlIds.length === data.length;
    },
    imageOverlay() {
      const { imagePreviewOverlay } = this;
      return imagePreviewOverlay ? `v-data-table--schedule__image-overlay` : null;
    },
    ...mapGetters({
      selectedViewCriteria: 'ScheduleViews/selectedViewCriteria',
      isExistSelectedControlProductsIds: 'Collections/isExistSelectedControlProductsIds',
      findUserRoleInLibraryForActiveHeader: 'UserRoles/findUserRoleInLibraryForActiveHeader',
      selectedView: 'Collections/selectedCollectionView',
      projectsTableOptimization: 'FeatureFlags/projectsTableOptimization',
    }),
    readMode() {
      const { params } = this.$route;
      return !params.id || this.isSharedCollection;
    },
    collectionId() {
      return this.$route.params.id;
    },
    dataForTable() {
      if (this.$store.state.Collections.isFetching.products) {
        return [...this.data,
          {
            id: this.data.length.toString(),
            skeleton: true,
          },
          {
            id: (this.data.length + 1).toString(),
            skeleton: true,
          },
        ];
      } else {
        return this.data;
      }
    },
  },
  watch: {
    async isShown(isShown) {
      if (!isShown) return;
      await waitTimeout(700);
      this.tableHeight = this.getOffsetHeight();
    },
  },
  async mounted() {
    watchBy('data', async (newVal, unsubscribe) => {
      if (newVal.length) {
        await waitTimeout(700);
        this.tableHeight = this.getOffsetHeight();
        const hoveredColumns = this.selectedView.columnOrder.reduce((acc = {
        }, column) => {
          acc[column] = Object.freeze({
            isResized: false,
            active: false,
            clicked: false,
          });
          return acc;
        }, {
        });
        this.initHoveredColumns(hoveredColumns);
        unsubscribe();
      }
    }, this);
  },
  methods: {
    ...mapMutations(['spinner']),
    ...mapMutations('Collections', [
      'setProductToUpdate',
      'setSelectedControlProductsIds',
      'changeUpdateProductMode',
      'changeProductModalVariant',
    ]),
    ...mapMutations('ProjectDetailsTableSchedule', ['initHoveredColumns']),
    ...mapActions('Collections', ['setCollectionsViewsSorting', 'openUpdateProductModal']),
    sortHelper,
    getOffsetHeight() {
      const { ['v-data-table--schedule']: ref } = this.$refs ?? {
      };
      if (!ref) return 0;
      const { $el } = ref ?? {
      };
      if (!$el) return 0;
      const { offsetHeight } = $el.querySelector('table') ?? {
      };
      if (!offsetHeight) return 0;
      return offsetHeight;
    },
    showCollectionActions({ id }) {
      const { isExistSelectedControlProductsIds, selectedControl } = this;
      const { params, path } = this.$route;
      return (params.id || path !== '/shared')
        && (selectedControl(id) || !isExistSelectedControlProductsIds);
    },
    selectedControl(id) {
      return this.$store.getters['Collections/selectedControl'](id);
    },
    showCommentsModalTrigger(val) {
      if (val) {
        this.setProductToUpdate(Object.values(val)[0]);
      } else {
        this.setProductToUpdate(null);
      }
    },
    deleteProductRow(deletedProduct) {
      this.$emit('deleteProductRow', deletedProduct);
    },
    changeControlSelects({ id, value } = {
    }) {
      const { selectedControlIds: idsArray } = this;
      if (id) {
        if (idsArray.includes(id)) {
          this.selectedControlIds = idsArray.filter(itemId => itemId !== id);
        } else {
          this.selectedControlIds = [...idsArray, id];
        }
      } else if (value) {
        this.selectedControlIds = this.data.map(({ id }) => id);
      } else {
        this.selectedControlIds = [];
      }
    },
    shouldShowProductCell({ colIndex, item }) {
      if (item.skeleton) return false;

      return this.showColumns({
        rowId: item.id,
        colIndex: colIndex,
      });
    },
  },
};
</script>
<style scoped lang="scss">
::v-deep .v-data-table {
  height: 20%;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
</style>
