<template>
  <!-- :class="!item.publish ? 'defaultButton' : 'disableButton'" -->
  <app-icon-btn
    btn-class="mr-1"
    :access="canWorkWithPublish"
    class="collection-action-button"
    :class="[!isLibrary && 'nav-card-action nav-card-action__reversed']"
    :wrapper-class="'d-flex'"
    :icon-height="isLibrary ? 24 : 0"
    bottom
    icon-name="mdi-publish"
    show-tooltip
    @click="changeStatus">
    <p
      data-test="publish_collection_button"
      class="collection-action-button__header-text">
      Share
    </p>
  </app-icon-btn>
</template>
<script>
import {
  mapMutations,
  mapActions,
  mapGetters,
  mapState,
} from 'vuex';
import subscribeSharingOption from '@/mixins/subscribeSharingOption';
import { accessHelper } from '@/utils';
export default {
  name: 'CollectionPublishV2',
  mixins: [subscribeSharingOption],
  props: {
    isListing: {
      type: Boolean,
      default: false,
    },
    statusMenu: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: null,
    },
    isLibrary: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('UserRoles', ['canPublishCollection', 'findUserRoleInLibraryForActiveHeader']),
    ...mapGetters(['userId']),
    ...mapState(['activeHeader']),
    ...mapState('Collections', ['sharingDialog']),
    textPublished() {
      return 'You are publishing this Collection to the Sourcery Community; anyone with a link will be able to view, follow and suggest that others follow this collection.';
    },
    canWorkWithPublish() {
      return accessHelper(true);
    },
  },
  watch: {
    statusMenu(val) {
      if (val && this.subscribe?._state === 'closed') this.subscribeSharing();
    },
    sharingDialog(val) {
      if (val && this.isListing) this.subscribe?.unsubscribe();
    },
  },
  mounted() {
    this.subscribeSharing();
  },
  beforeDestroy() {
    this.subscribe?.unsubscribe();
  },
  methods: {
    ...mapActions({
      switchTypeSharingAction: 'Collections/switchTypeSharing',
    }),
    ...mapMutations({
      openSharingDialog: 'Collections/setSharingDialog',
      spinner: 'spinner',
    }),
    async changeStatus() {
      this.openSharingDialog(true);
      return false;
    },
    async publish() {
      if (!this.canWorkWithPublish.allowed) {
        return;
      }
      let confirmPublishItem = await this.$openConfirm({
        text: this.textPublished,
      });
      this.spinner(true);
      if (confirmPublishItem) await this.switchTypeSharing('publish');
    },
    async unpublish() {
      if (!this.canWorkWithPublish.allowed) {
        return;
      }
      let confirmPublishItem = await this.$openConfirm({
        text: `Do you want to unpublish this collection ?`,
      });
      this.spinner(true);
      if (confirmPublishItem) await this.switchTypeSharing('private');
    },
    async switchTypeSharing(type) {
      await this.switchTypeSharingAction({
        type,
        collectionId: this.item.id,
      });
    },
  },
};
</script>
<style scoped lang="scss">
.v-icon {
  cursor: pointer;
  margin-right: 4px !important;
}
.small-p {
  padding: 0 !important;
}
.defaultButton {
  cursor: pointer;
}
.disableButton {
  opacity: 0.5;
  cursor: default;
  .v-icon {
    cursor: default;
  }
}
</style>
