<template>
  <fragment>
    <div
      v-if="showHeaderActions"
      class="buttons-actions mt-5">
      <followers-amount
        :followers-amount="getFollowersAmount" />
      <template
        v-if="showCollectionPublish">
        <CollectionPublishV2
          :item="activeHeader" />
      </template>
      <CollectionFollow
        :is-show-title-icon="false"
        :is-community-collections="isCommunityCollections"
        :item="activeHeader" />
      <v-btn
        v-if="isActiveSuggestionCollection"
        color="grey"
        style="min-height: 36px"
        @click="ignoreSuggestionHandle">
        Ignore
      </v-btn>
      <SuggestCollectionDialog
        v-if="!isHiddenExpandedActions"
        :collection-id="activeHeader.id" />
      <collection-action-detail
        :disable-actions="disableActions"
        :item="activeHeader" />
      <embed-code-dialog />
    </div>
    <div
      v-else-if="isSharedCollection && useAddCollectionFollowButtonsToQuickLinks"
      class="mt-5">
      <follow-link-btn :link="followLink" />
    </div>
  </fragment>
</template>
<script>
import {
  mapState, mapGetters,
} from 'vuex';
import appFollowersAmount from '@/components/App/AppFollowersAmount';
import CollectionPublishV2 from '@/components/Collections/CollectionsActions/CollectionPublishV2';
import CollectionFollow
  from '@/components/Collections/CollectionsActions/CollectionsActionsComponent/CollectionFollow';
import SuggestCollectionDialog from '@/components/App/AppSuggestDialog/SuggestCollectionDialog';
import CollectionEmbedCodeDialog
  from '@/components/CollectionDetails/CollectionEmbedCode/CollectionEmbedCodeDialog';
import CollectionActionDetail from '@/components/CollectionDetails/CollectionActionDetail';
import { ROUTE_COMMUNITY_COLLECTION } from '@/constants';
import manageCollectionsActions from '@/mixins/ManageCollectionsActions';
import followLinkBtn
  from '@/components/Collections/CollectionsActions/CollectionsActionsComponent/CollectionFollow/FollowLinkBtn';
import { addSearchParams } from '@/utils';
export default {
  name: 'CollectionHeaderActions',
  components: {
    followersAmount: appFollowersAmount,
    CollectionFollow,
    SuggestCollectionDialog,
    embedCodeDialog: CollectionEmbedCodeDialog,
    CollectionPublishV2,
    CollectionActionDetail,
    followLinkBtn,
  },
  mixins: [manageCollectionsActions],
  props: {
    readMode: {
      type: Boolean,
      default: false,
    },
    isCommunityCollections: {
      type: Boolean,
      default: false,
    },
    isSharedCollection: {
      type: Boolean,
      default: false,
    },
    activeHeader: {
      type: Object,
      default: () => ({
      }),
      required: true,
    },
    isEditing: {
      type: [Object, Boolean],
      default: false,
    },
  },
  computed: {
    ...mapState('Collections', ['currentCollectionSharingOption']),
    ...mapGetters('FeatureFlags', [
      'useAddCollectionFollowButtonsToQuickLinks',
      'mobileViewOptimizationsForTradeShow',
    ]),
    ...mapGetters('UserRoles', ['canPublishInCurrentWorkspace']),
    ...mapGetters('Collections', ['isGroupShared', 'isActiveSuggestionCollection', 'getFollowersAmount', 'checkIfMyCollection']),
    isHiddenExpandedActions() {
      const { mobileViewOptimizationsForTradeShow: flag } = this;
      return flag && this.$vuetify.breakpoint.smAndDown;
    },
    followLink() {
      const { url = '' } = this.currentCollectionSharingOption ?? {
      };
      return addSearchParams(url, {
        action: 'followV2',
      })?.href ?? '';
    },
    showCollectionPublish() {
      return this.disableActions && this.canPublishInCurrentWorkspace
        && (this.checkIfMyCollection || this.isGroupShared);
    },
    showHeaderActions() {
      return (
        ['collection-library', ROUTE_COMMUNITY_COLLECTION].includes(
          this.$route.name
        ) &&
        !this.isEditing &&
        !this.readMode
      );
    },
    disableActions() {
      return ![ROUTE_COMMUNITY_COLLECTION, 'community-collections'].includes(
        this.$route.name
      );
    },
  },
  methods: {
    async ignoreSuggestionHandle() {
      await this.manageActions({
        action: 'ignoreSuggestion', item: this.activeHeader,
      });
      this.$router.push({
        name: 'community-collections',
        params: {
          id: 'community',
        },
      });
    },
  },
};
</script>
<style scoped>
</style>
