<template>
  <v-card-title>
    <div
      :class="themeColor"
      class="d-flex w-100">
      <div
        :style="{background:bgColor}"
        class="circle-icon-wrapper mr-5">
        <v-icon
          size="20"
          color="grey">
          {{ iconName }}
        </v-icon>
      </div>
      <div>
        <slot name="textTitle">
          <span>Sharing, visibility and suggest to others</span>
        </slot>
        <p class="body-2">
          <slot name="subtitle" />
        </p>
      </div>
      <v-icon
        v-if="closedIcon"
        class="closed align-self-start ml-auto"
        color="#fff"
        :disabled="isDisabledCloseIcon"
        @click.stop="$emit('closeModal');">
        mdi-close
      </v-icon>
    </div>
  </v-card-title>
</template>
<script>
export default {
  name: 'HeaderShareAdvanced',
  props: {
    closedIcon: {
      type: Boolean,
      default: true,
    },
    themeColor: {
      type: String,
      default: 'white-theme',
    },
    bgIconColor: {
      type: String,
      default: 'lightBlue',
    },
    iconName: {
      type: String,
      default: 'mdi-share-variant',
    },
    isDisabledCloseIcon: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    bgColor() {
      return `var(--v-${this.bgIconColor}-base)`;
    },
  },
};
</script>
<style lang="scss" scoped>
p{
  margin: 0 !important;
}
</style>
