<template>
  <div class="header-actions">
    <CollectionShareDialog
      :members="item.members"
      :invite-to-text-type="LIBRARY"
      @manageSharedUser="manageShare" />
    <library-delete
      icon-color="rgba(0, 0, 0, 0.54)"
      :replace-router="{name:'libraries'}"
      icon-size="30"
      :item="item" />
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import CollectionShareDialog from '@/components/CollectionsLibrarysRelocate/ShareDialog';
import { LIBRARY } from '@/constants/cores';
import LibraryDelete
  from '@/components/Libraries/LibrariesActions/LibraryActionsComponent/LibraryDelete';
export default {
  name: 'LibrarySubHeaderActions',
  components: {
    LibraryDelete,
    CollectionShareDialog,
  },
  props: {
    item: {
      type: Object,
      default: () => {
      },
    },
  },
  data() {
    return {
      LIBRARY,
    };
  },
  methods: {
    ...mapActions({
      manageSharedUser: 'Libraries/manageSharedUser',
    }),
    manageShare(data) {
      this.manageSharedUser({
        variables: {
          ...data.variables,
          libraryId: this.item.id,
        },
        criteria: data.criteria,
      });
    },
  },
};
</script>
<style scoped lang="scss">
.header-actions {
  margin-top: 10px;
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: flex-start;
  div {
    cursor: pointer;
  }
}
</style>
