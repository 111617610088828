<template>
  <v-expansion-panels
    v-if="generalCommentsModal"
    v-model="commentsPanel"
    class="main-panel px-sm-5 px-md-8"
    accordion
    multiple>
    <ModalHeader
      :resource-type="resourceType"
      :resource-id="resourceId"
      @close-dialog="$emit('close-dialog')" />
    <ModalBody
      :resource-type="resourceType"
      :resource-id="resourceId"
      :general-comments-modal="generalCommentsModal" />
  </v-expansion-panels>
</template>
<script>
import ModalHeader from '@/components/App/AppComments/AppSummaryModalContent/ModalHeader';
import ModalBody from '@/components/App/AppComments/AppSummaryModalContent/ModalBody';
import CommentsResources from '@/mixins/CommentsResources';
export default {
  name: 'AppSummaryModalContent',
  components: {
    ModalHeader,
    ModalBody,
  },
  mixins: [CommentsResources],
  inheritAttrs: false,
  props: {
    generalCommentsModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      commentsPanel: [],
      comments: [],
    };
  },
  computed: {
    commentsLength() {
      return this.comments.length;
    },
  },
};
</script>
<style scoped lang="scss">
@import '~vuetify/src/styles/settings/_variables';
.main-panel {
  border-radius: 10px !important;
  background: #EDF0F7 !important;
  display: grid;
  gap: 24px;
  padding: 28px 32px 55px 32px;
  justify-content: normal;
  grid-template-columns: 1fr;
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    padding: 16px;
  }
  ::v-deep .header-items {
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    .main-text {
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
    }
    .comments {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      .action-buttons {
        display: flex;
        gap: 7px;
        align-items: center;
        justify-content: flex-end;
        flex-wrap: wrap;
        .ex-and-collapse {
          border: 1px solid #B7C2C5;
          border-radius: 5px;
          padding: 0px !important;
          height: 28px !important;
          .v-icon {
            background: #B7C2C5;
            border-radius: 4px;
            padding: 6px;
          }
          span {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 126.19%;
            color: var(--v-secondary-base);
            padding: 2px 8px;
          }
        }
      }
    }
  }
  @media (max-width: 960px) {
    ::v-deep .all-comments {
      grid-template-columns: 1fr !important;
      .cards, .default-comments {
        height: fit-content !important;
      }
    }
  }
  ::v-deep .all-comments {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    .cards {
      height: 540px !important;
    }
    .default-comments {
      height: 546px;
      overflow: auto;
    }
    .v-card {
      box-shadow: none;
    }
  }
}
</style>
