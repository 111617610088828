<template>
  <div
    class="px-0 d-flex align-center">
    <group-modal
      :disabled="disabled"
      hide-close-icon-bottom
      hide-close-icon-top>
      <template #topHeader="{closeModal,statusHeader}">
        <header-share
          :closed-icon="statusHeader"
          :header-title="showUpdatesToWorkspaceGuests ? 'Add Guests' : 'Add Members'"
          :dialog-type="dialogType"
          :bg-icon-color="!statusHeader ? 'greyDarken' : 'lightBlue'"
          :color-title="!statusHeader ? '#000' : '#fff'"
          @closeModal="closeModal">
          <template #subtitle>
            {{ `${showUpdatesToWorkspaceGuests ? 'Guests' : 'Members'} can collaborate with you on this collection and can see each other.` }}
          </template>
        </header-share>
        <v-card-title v-if="!statusHeader">
          <p class="text--secondary body-2 ml-3 text-truncate">
            Shared with {{ listSharingName }}
          </p>
        </v-card-title>
      </template>
      <template #topContent="{closeModal, statusDialog}">
        <share-content
          :item="itemToShare"
          :permission-types="permissionTypes"
          :status-dialog="statusDialog"
          :disabled="!(disableActions && !item.follow)"
          :is-loading="isFetchingMembers"
          view-in-actions
          :members="itemMembers"
          :invite-to-text-type="COLLECTION"
          @closeModal="closeModal"
          v-on="$listeners" />
      </template>
      <template #bottomHeader="{statusHeader,closeModal}">
        <header-share-advanced
          :closed-icon="statusHeader"
          :is-disabled-close-icon="isDisabledCloseIcon"
          :theme-color="statusHeader ? 'dark-theme' : 'white-theme'"
          :bg-icon-color="!statusHeader ? 'greyDarken' : 'lightBlue'"
          @closeModal="closeModal">
          <template #subtitle>
            Share with the community and suggest that others follow this collection.
          </template>
        </header-share-advanced>
        <v-card-title v-if="!statusHeader">
          <v-row class="align-center overflow-hidden">
            <v-col cols="9">
              <p class="caption text-truncate ml-3">
                <span class="font-weight-bold"> {{ selectedItem && selectedItem.name }} </span>
                <span class="text--secondary">{{ selectedItem && selectedItem.message }}</span>
              </p>
            </v-col>
            <v-col cols="3">
              <span
                class="color-link body-1"
                @click.stop="copyToClipBoard(selectedItem.url,'Link copied')">Copy link</span>
            </v-col>
          </v-row>
        </v-card-title>
      </template>
      <template #bottomContent="{statusDialog,closeModal}">
        <share-content-advanced
          :start-subscribe="startSubscribe"
          :dialog-type="dialogType"
          :invite-to-text-type="inviteToTextType"
          :status-dialog="statusDialog"
          :item="itemToShare"
          :selected-item.sync="selectedItem"
          @onPossibleToCloseDialog="isDisabledCloseIcon = !$event"
          v-on="$listeners"
          @closeModal="closeModal" />
      </template>
    </group-modal>
  </div>
</template>
<script>
import {
  mapGetters, mapState, mapMutations,
} from 'vuex';
import ShareContentAdvanced from '@/components/CollectionsLibrarysRelocate/ShareContentAdvanced';
import HeaderShareAdvanced from '@/components/CollectionsLibrarysRelocate/HeaderShareAdvanced';
import ShareContent from '@/components/CollectionsLibrarysRelocate/ShareContent';
import HeaderShare from '@/components/CollectionsLibrarysRelocate/headerShare';
import GroupModal from '@/components/App/AppGroupModal';
import { TYPE_COLLECTION_SHARE } from '@/constants';
import { COLLECTION } from '@/constants/cores';
import { GET_PERMISSION_TYPES } from '@/constants/userPermissions';
import { copyToClipBoard } from '@/utils';
import CollectionsApi from '@/services/graphql/collections';
export default {
  name: 'ShareAdvancedDialog',
  components: {
    GroupModal, HeaderShare, ShareContent, HeaderShareAdvanced, ShareContentAdvanced,
  },
  props: {
    startSubscribe: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    inviteToTextType: {
      type: String,
      default: undefined,
    },
    permissionTypes: {
      type: Array,
      default: () => GET_PERMISSION_TYPES(['viewer', 'editor', 'creator']),
    },
    item: {
      type: Object,
      default: () => null,
    },
    itemGroup: {
      type: Object,
      default: () => null,
    },
    dialogType: {
      type: String,
      default: TYPE_COLLECTION_SHARE,
    },
    disableActions: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedItem: null,
      isFetchingMembers: false,
      fetchedMembers: [],
      updatedItem: null,
      COLLECTION,
      isDisabledCloseIcon: false,
    };
  },
  computed: {
    ...mapState('Workspace', ['activeWorkspaceId']),
    ...mapGetters('FeatureFlags', ['showUpdatesToWorkspaceGuests']),
    itemToShare() {
      return this.updatedItem ?? this.item;
    },
    itemMembers() {
      if (this.isFetchingMembers) return [];
      if (this.fetchedMembers?.length > 0) return this.fetchedMembers;
      if (this.item?.members) return this.item.members;
      if (this.item?.memberSummary?.members) return this.item.memberSummary.members;
      return [];
    },
    listSharingName() {
      if (!this.itemMembers) return '';
      const members = this.itemMembers ?? [];
      return members.map(e => e.name).join(', ');
    },
  },
  watch: {
    item: {
      async handler(val) {
        this.updatedItem = null;
        this.fetchedMembers = [];

        if (this.shouldUpdateItemMemberInfo(val)) {
          this.isFetchingMembers = true;
          try {
            await this.updateItemMemberInfo(val);
          } finally {
            this.isFetchingMembers = false;
          }
        }
      },
    },
  },
  methods: {
    ...mapMutations({
      spinner: 'spinner',
    }),
    copyToClipBoard,
    shouldUpdateItemMemberInfo(item) {
      if (!item) return false;
      if (!item.members) return true;
      if (item.memberSummary) {
        if (item.memberSummary.members.length == item.memberSummary.count) {
          return false;
        }
      }
      return true;
    },
    async updateItemMemberInfo(item) {
      try {
        this.spinner(true);

        const response = await CollectionsApi.getMembersWithGrants({
          collectionId: item.id,
          collectionName: item.name,
          workspaceId: this.activeWorkspaceId,
        });
        const updatedMembers = response.data.response;
        this.fetchedMembers = updatedMembers;

        this.updatedItem = {
          ...item,
          members: updatedMembers,
        };
        this.commitMemberInfoToStore(this.updatedItem);
      } catch (err) {
        console.error('Error fetching member data for item', err);
      } finally {
        this.spinner(false);
      }
    },
    commitMemberInfoToStore(updatedItem) {
      if (!this.itemGroup) return;

      try {
        // Either the type that uses a getter, or the type that has state.
        const listingMethod = this.itemGroup.list;
        let collectionList = this.isGetterType(listingMethod)
          ? this.$store.getters[listingMethod]
          : this.$store.state.Collections[listingMethod];
        if (collectionList) {
          collectionList = collectionList.map(c => c.id === updatedItem.id ? updatedItem : c);
          this.$store.commit(`Collections/${this.itemGroup.mutationName}`, collectionList);
        }
      } catch (err) {
        console.warn('Could not commit fetched members to store.', err);
      }
    },
    isGetterType(listingMethod) {
      return listingMethod?.includes('/');
    },
  },
};
</script>
<style scoped>
p {
  margin: 0;
}
</style>
