<template>
  <div
    class="header-items"
    style="gap: initial;">
    <div class="d-flex">
      <span
        class="main-text">
        <b>{{ chatName }}</b>
      </span>
      <ToggleResolvedComments
        :resource-id="resourceId"
        :resource-type="resourceType"
        class="ml-sm-8 ml-xs-2 mt-0" />
    </div>
    <div
      class="comments pa-md-0 px-sm-0 col-xs-12 col-sm justify-end">
      <div
        class="action-buttons">
        <v-btn
          :disabled="!totalUnreadComments"
          large
          class="ex-and-collapse"
          @click="setReadAllResourceComments({
            resourceId,
            resourceType,
          })">
          <span>MARK ALL AS READ</span>
        </v-btn>
        <v-icon
          color="lightBlack"
          @click="$emit('close-dialog')">
          mdi-close
        </v-icon>
      </div>
    </div>
  </div>
</template>
<script>
import AppCommentsToggleResolved from '@/components/App/AppComments/AppCommentsToggleResolved';
import CommentsResources from '@/mixins/CommentsResources';
import { mapActions } from 'vuex';
export default {
  name: 'ModalHeader',
  components: {
    ToggleResolvedComments: AppCommentsToggleResolved,
  },
  mixins: [CommentsResources],
  props: {
    chatName: {
      type: String,
      default: 'Collection Chat',
    },
  },
  methods: {
    ...mapActions('Comments', ['setReadAllResourceComments']),
  },
};
</script>
