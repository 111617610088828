<template>
  <app-dialog
    v-model="dialog"
    :value.sync="dialog"
    width="720">
    <template #activator="{ on, attrs }">
      <div
        v-bind="attrs"
        v-on="on">
        <slot name="commentsBtn">
          <CommentsBtn :total-unread-comments="totalUnreadComments" />
        </slot>
      </div>
    </template>
    <ModalContent
      :general-comments-modal="dialog"
      :resource-id="resourceId"
      :resource-type="resourceType"
      @close-dialog="dialog = false" />
  </app-dialog>
</template>
<script>
import AppSummaryModalContent from '@/components/App/AppComments/AppSummaryModalContent';
import AppSummaryCommentsBtn from '@/components/App/AppComments/AppSummaryCommentsBtn';
import { COMMENT_RESOURCE_TYPE } from '@/constants/comments';
import {
  mapState, mapGetters,
} from 'vuex';
import CommentsResources from '@/mixins/CommentsResources';
export default {
  name: 'AppSummaryComments',
  components: {
    ModalContent: AppSummaryModalContent,
    CommentsBtn: AppSummaryCommentsBtn,
  },
  mixins: [CommentsResources],
  props: {
    generalCommentsModal: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('Collections', ['selectedCollectionView']),
    ...mapState('ScheduleViews', ['selectedViewId']),
    getSelectedViewId() {
      if (this.resourceType == COMMENT_RESOURCE_TYPE.COLLECTION) {
        return this.selectedCollectionView?.id;
      }
      return this.selectedViewId;
    },
    dialog: {
      get() {
        return this.generalCommentsModal;
      },
      set(val) {
        this.$emit('update:generalCommentsModal', val);
      },
    },
  },
  watch: {
    dialog(val) {
      this.$router.replace({
        query: {
          ...this.$route.query,
          generalComments: val || undefined,
          view: val ? this.getSelectedViewId : undefined,
        },
      }).catch(() => {
      });
    },
  },
};
</script>
<style scoped>
</style>
