<template>
  <app-dialog
    v-model="dialog"
    :value.sync="dialog"
    width="637"
    content-class="v-dialog__form">
    <template #activator="{ on }">
      <app-action-btn
        v-if="isIcon"
        show-tooltip
        top
        :access="disabled"
        v-on="disabled.allowed && on">
        <template #iconWrapper>
          <v-icon
            size="20">
            mdi-message-bulleted
          </v-icon>
        </template>
        <span>
          Suggest Followers
        </span>
      </app-action-btn>
      <app-action-btn
        v-else-if="!showInKebabAction"
        top
        show-tooltip
        data-test="suggest_followers_button"
        wrapper-class="collection-action-button__header-text"
        class="nav-card-action"
        :access="disabled"
        v-on="disabled.allowed && on">
        Suggest Followers
      </app-action-btn>
      <app-btn
        v-else
        top
        color="lightBlue"
        class="mr-2"
        show-tooltip
        :access="disabled"
        v-on="disabled.allowed && on">
        Suggest Followers
      </app-btn>
    </template>
    <v-card>
      <v-card-title>
        <div class="d-flex align-center">
          <div
            class="circle-icon-wrapper mr-5 lightBlue">
            <v-icon
              size="20"
              color="#fff">
              mdi-comment-multiple-outline
            </v-icon>
          </div>
          Suggest to people and groups
        </div>
        <v-icon
          color="#fff"
          @click="dialog = false">
          mdi-close
        </v-icon>
      </v-card-title>
      <v-card-text v-if="dialog">
        <ShareAutocomplete
          collection-share
          check-email-errors
          invite-to-message="Press enter to send the suggestion"
          shared-autocomplete
          @selected="selected">
          <template #name="{ onSave }">
            <p @click="onSave" />
          </template>
        </ShareAutocomplete>
        <p
          v-if="showValidationEmailErrors && validationEmailErrors.length"
          class="mt-2 mb-2 error--text">
          {{ ErrorsText.WRONG_EMAILS_FOR_SHARING(validationEmailErrors) }}
        </p>
        <v-textarea
          v-model="emailMessage"
          class="c-textarea"
          placeholder="Message" />
      </v-card-text>
      <v-card-actions class="v-card__actions sticky-bottom pb-4 px-8">
        <v-spacer />
        <v-btn
          class="mr-2"
          outlined
          color="lightBlue"
          @click="dialog = false">
          Cancel
        </v-btn>
        <v-btn
          class="ml-auto"
          color="lightBlue"
          @click="save">
          Send
        </v-btn>
      </v-card-actions>
    </v-card>
  </app-dialog>
</template>
<script>
import ShareAutocomplete from '@/components/App/AppShareAutocomplete';
import ErrorsText from '@/constants/errors';
import { isValidEmail } from '@/utils';
import { debounce } from 'lodash';
export default {
  name: 'SuggestDialog',
  components: {
    ShareAutocomplete,
  },
  props: {
    isIcon: {
      type: Boolean,
      default: false,
    },
    showInKebabAction: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Object,
      default: () => {},
    },
    suggestHandler: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      dialog: false,
      emailMessage: '',
      selectedUsers: [],
      showValidationEmailErrors: false,
      ErrorsText,
    };
  },
  computed: {
    validationEmailErrors() {
      const changedUsers = [this.selectedUsers];
      if (!changedUsers.length) {
        return [];
      }
      return changedUsers.flatMap(users => users.filter(user => !isValidEmail(user)));
    },
  },
  watch: {
    dialog(val) {
      if (val) {
        this.selectedUsers = [];
        this.showValidationEmailErrors = false;
        this.emailMessage = '';
      }
    },
  },
  methods: {
    selected(data) {
      this.selectedUsers = data;
    },
    save: debounce(async function() {
      if (this.validationEmailErrors.length) {
        this.showValidationEmailErrors = true;
        return;
      }
      this.showValidationEmailErrors = false;
      this.suggestItemsHandler();
    }, 0),
    extractEmail(user) {
      return typeof user === 'string' ? user : user?.email;
    },
    async suggestItemsHandler() {
      this.dialog = false;
      this.suggestHandler({
        selectedUsers: this.selectedUsers,
        emailMessage: this.emailMessage,
      });
    },
  },
};
</script>
<style scoped lang="scss">
  .error--text {
    font-size: 14px;
  }
  .nav-card-action {
    background: linear-gradient(120deg, #CF0072 -19.12%, #E9994A 102.38%);
    ::v-deep &__suggest {
      font-size: 16px;
      padding: 0px !important;
      color: #fff !important;
    }
  }
</style>
