<template>
  <div>
    <input-shared-link :url="url">
      <template #bottom-text>
        Share Link for Sourcery members only (sent to potential followers via email etc...)
      </template>
    </input-shared-link>
    <v-row
      class="mx-sm-6 mx-4 mb-16 mt-2">
      <v-col cols="12">
        <v-tooltip
          :disabled="canWorkWithModifyShare"
          content-class="btn-tooltip"
          bottom>
          <template #activator="{on}">
            <div
              class="select-option"
              v-on="on">
              <v-select
                v-model="value"
                :disabled="!isPossibleToCloseDialog || !canWorkWithModifyShare"
                :menu-props="{contentClass:'sharing-menu'}"
                :items="items"
                solo
                return-object
                flat
                hide-no-data
                disable-lookup
                item-value="type"
                item-text="name">
                <template #item="{item,on}">
                  <v-list-item
                    :class="{'v-item--active v-list-item--active': item.type === value.type }"
                    @click="updateUrl(item.type,on)">
                    <v-list-item-title>
                      {{ item.name }}
                    </v-list-item-title>
                  </v-list-item>
                </template>
                <template #selection>
                  <b> {{ value.name }}</b>
                </template>
                <template #prepend>
                  <div class="circle-icon-wrapper">
                    <v-icon
                      color="#fff"
                      size="20">
                      {{ value.icon }}
                    </v-icon>
                  </div>
                </template>
              </v-select>
            </div>
          </template>
          <span>{{ NOT_PERMITTED_ACCESS }}</span>
        </v-tooltip>
        <p class="text--secondary caption">
          {{ value.message }}
        </p>
      </v-col>
    </v-row>
    <v-card-actions class="sticky-bottom pb-4 px-8">
      <v-spacer />
      <SuggestCollectionDialog
        :collection-id="item.id"
        class="mr-2"
        show-in-kebab-action />
      <v-btn
        :disabled="!isPossibleToCloseDialog"
        color="lightBlue"
        @click="save">
        Done
      </v-btn>
    </v-card-actions>
  </div>
</template>
<script>
import {
  mapActions, mapMutations, mapGetters, mapState,
} from 'vuex';
import { copyToClipBoard } from '@/utils';
import {
  PRIVATE,
  UNLISTED_PUBLISH,
  PUBLISH, TYPE_COLLECTION_SHARE,
} from '@/constants';
import { NOT_PERMITTED_ACCESS } from '@/constants/userPermissions';
import subscribeSharingOption from '@/mixins/subscribeSharingOption';
import InputSharedLink from '@/components/App/AppSharedLink';
import ShareMixin from '@/mixins/ShareMixin';
export default {
  name: 'ShareContentAdvanced',
  components: {
    InputSharedLink,
    SuggestCollectionDialog: () => import('../../App/AppSuggestDialog/SuggestCollectionDialog'),
  },
  mixins: [subscribeSharingOption, ShareMixin],
  props: {
    startSubscribe: {
      type: Boolean,
      default: false,
    },
    statusDialog: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: null,
    },
    inviteToTextType: {
      type: String,
      default: undefined,
    },
    dialogType: {
      type: String,
      default: TYPE_COLLECTION_SHARE,
    },
  },
  data() {
    return {
      isPossibleToCloseDialog: true,
      NOT_PERMITTED_ACCESS,
      TYPE_COLLECTION_SHARE,
      value: {
        url: '',
      },
    };
  },
  computed: {
    ...mapState('Collections', ['currentCollectionSharingOption']),
    ...mapGetters('UserRoles', [
      'canShareLibraryOrCollection',
      'findUserRoleInLibraryForActiveHeader',
      'findUserRoleInLibrary',
    ]),
    ...mapGetters('FeatureFlags', ['showUpdatesToWorkspaceGuests']),
    items() {
      const userType = this.showUpdatesToWorkspaceGuests ? 'Guests' : 'Members';
      return [{
        name: 'Private Publish',
        type: UNLISTED_PUBLISH,
        message: `${userType} or anyone with a link can view, follow and suggest that others follow this collection`,
        icon: 'mdi-incognito',
      }, {
        name: 'Community Publish',
        type: PUBLISH,
        message: `Collection will be listed among community collections. \
                  ${userType} or anyone with a link can view, follow and suggest that others follow this collection`,
        icon: 'mdi-account-group',
      }, {
        name: 'Locked to Workspace',
        type: PRIVATE,
        message: `${userType} or anyone with a link can view this collection inside the current workspace only. \
                  ${userType} will not be able to follow, view or add products to projects in other workspaces.`,
        icon: 'mdi-account',
      }];
    },
    url() {
      return this.value?.url || '';
    },
    textPublished() {
      return 'You are publishing this Collection to the Sourcery Community; budget pricing will be hidden ' +
        'and anyone with a link will be able to view, follow and suggest that others follow this collection.';
    },
    textUnpublished() {
      return 'Do you want to unpublish this collection ?';
    },
  },
  watch: {
    statusDialog: {
      async handler(val) {
        const { startSubscribe } = this;
        if (!val) {
          startSubscribe && this.subscribe?.unsubscribe();
          return false;
        }
        this.spinner(true);
        startSubscribe && await this.subscribeSharing(startSubscribe);
        await this.getCollectionSharingOptions({
          id: this.item.id,
        });
        const { url, type } = this.currentCollectionSharingOption || {
        };
        const findShareType = this.items.find(e => e.type === type);
        this.value = {
          ...findShareType, url,
        };
        this.spinner(false);
      },
      immediate: true,
    },
    value: {
      handler(val) {
        this.$emit('update:selectedItem', {
          ...val,
        });
      },
      immediate: true,
      deep: true,
    },
    isPossibleToCloseDialog: {
      handler(val) {
        this.$emit('onPossibleToCloseDialog', val);
      },
    },
  },
  methods: {
    ...mapMutations(['spinner']),
    ...mapActions({
      handleError: 'handleError',
      getCollectionSharingOptions: 'Collections/getCollectionSharingOptions',
      switchTypeSharing: 'Collections/switchTypeSharing',
    }),
    copyToClipBoard,
    async save() {
      if (this.isPossibleToCloseDialog) {
        this.$emit('closeModal');
      }
    },
    async updateUrl(type, on) {
      const { textPublished, textUnpublished, value } = this;
      const text = type === PUBLISH ? textPublished : textUnpublished;
      if ((type === this.value?.type) || ((type === PUBLISH || value.type === PUBLISH && type !== PUBLISH) && !(await this.$openConfirm({
        text,
      })))) return false;
      this.isPossibleToCloseDialog = false;
      try {
        on.click();
        const collectionId = this.item?.id;
        await this.switchTypeSharing({
          collectionId,
          type,
        });
        const { url } = this.currentCollectionSharingOption || {
        };
        this.$set(this.value, 'url', url);
      } catch (e) {
        console.error(e);
      } finally {
        this.isPossibleToCloseDialog = true;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.caption {
  margin: -5px 0 0 48px;
}
.select-option {
  max-width: 260px;
}
::v-deep .v-text-field.v-text-field--solo .v-input__prepend-outer {
  align-self: end;
  margin: 0 !important;
}
::v-deep .v-input__slot {
  margin: 0px !important;
  padding-left: 15px !important;
}
::v-deep .v-text-field.v-text-field--enclosed .v-text-field__details {
  margin-bottom: 0 !important;
}
::v-deep .v-text-field.v-text-field--solo .v-input__control {
  min-height: auto !important;
}
.circle-icon-wrapper {
  background: var(--v-lightGrey-base) !important;
}
::v-deep .v-text-field {
  height: 33px !important;
}
</style>
