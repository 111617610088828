<template>
  <div
    v-if="activeHeader"
    :class="{ 'mobile-view-trade-show-container': mobileViewOptimizationsForTradeShow }"
    class="collection-header">
    <div
      v-if="!renameItem"
      class="d-flex">
      <LogoItems
        :collection="activeHeader"
        :pictures="setPictures(activeHeader)" />
    </div>
    <HeaderLogos
      v-else-if="renameItem"
      :text-crop="isLibrary ? 'Crop to library logo aspect ratio' : 'Crop to collection logo aspect ratio'"
      :active-header="activeHeader"
      @addLogo="addLogo" />
    <div
      class="collection-container">
      <div class="collection-container__header">
        <div v-if="!renameItem">
          <v-tooltip
            :disabled="activeHeader.name && activeHeader.name.length < 43"
            top>
            <template #activator="{ on, attrs }">
              <div
                class="short-name"
                v-bind="attrs"
                v-on="on">
                <div class="mr-3">
                  {{ shortTitle }}
                </div>
                <div data-test="edit_collection_info_inside_button">
                  <app-icon-btn
                    v-if="
                      !renameItem && disableActions && !activeHeader.follow && !readMode
                    "
                    :access="canWorkWithModifyProduct"
                    show-tooltip
                    bottom
                    icon-color="black"
                    icon-height="30"
                    icon-name="mdi-pencil"
                    @click="canWorkWithModifyProduct.allowed && rename()" />
                </div>
              </div>
            </template>
            <span>{{ activeHeader.name }}</span>
          </v-tooltip>
          <div
            v-if="viewAsMember"
            class="view-as-member__link">
            {{ viewAsMember.text }}
            <a
              :href="viewMemberLink"
              target="_blank">{{ viewAsMember.name }}</a>
          </div>
          <div
            v-if="!createLibrary"
            class="collection-container__header__info">
            <div
              v-if="activeHeader.author"
              class="collection-container__header__info__item">
              <div>
                <v-icon class="hide-border">
                  mdi-human
                </v-icon>
              </div>
              <div>
                {{ activeHeader.author }}
              </div>
            </div>
            <div
              v-if="renderAddressText"
              class="collection-container__header__info__item">
              <div>
                <v-icon class="hide-border">
                  mdi-google-maps
                </v-icon>
              </div>
              {{ renderAddressText }}
            </div>
          </div>
        </div>
        <v-row
          v-else-if="renameItem"
          style="width: 100%"
          class="ma-0 flex-nowrap flex-column">
          <v-text-field
            ref="editInput"
            :value="editName"
            placeholder="Enter project name"
            class="edit-input-collection-name"
            autocomplete="off"
            @input="edit" />
          <div>
            <v-text-field
              v-if="!createLibrary"
              ref="editAuthor"
              :value="editAuthor"
              placeholder="Edit author"
              autocomplete="off"
              @input="(v) => (editAuthor = v)" />
          </div>
          <div v-if="!createLibrary">
            <location-field
              v-if="renameItem"
              :current-location="renderAddressText"
              @setLocationData="editLocation = $event"
              @setAddressData="editAddress = $event" />
            <v-text-field
              v-else
              id="placeAuto"
              class="google-input"
              placeholder="Location"
              :value="editLocation"
              autocomplete="off"
              @blur="() => (editLocation = address)"
              @focus="() => (editLocation = address)"
              @input="(e) => (editLocation = e)" />
          </div>
        </v-row>
      </div>
      <span
        v-if="!renameItem"
        class="collection-container__description">
        {{ activeHeader.description }}
      </span>
      <v-row
        v-else-if="renameItem"
        style="width: 100%"
        class="ma-0 flex-nowrap">
        <v-textarea
          ref="editInput"
          :rows="3"
          no-resize
          :value="editDescription"
          placeholder="Enter Description"
          class="edit-input-collection-description"
          autocomplete="off"
          @input="editDesc" />
      </v-row>
      <header-actions
        :is-editing="renameItem"
        :is-community-collections="isCommunityCollections"
        :is-shared-collection="isSharedCollection"
        :read-mode="readMode"
        :active-header="activeHeader" />
      <LibrarySubHeaderActions
        v-if="isLibrary && !renameItem"
        :item="activeHeader" />
      <div
        v-else-if="renameItem"
        class="buttons-actions">
        <v-btn
          class="publish"
          @click="saveEditedName(activeHeader)">
          Save
        </v-btn>
      </div>
    </div>
    <share-advanced-dialog
      v-if="renderSharingInHeader"
      :disable-actions="disableActions"
      :disabled="!(disableActions && !activeHeader.follow)"
      :invite-to-text-type="COLLECTION"
      :item="activeHeader"
      @manageSharedUser="
        manageSharedUser({
          variables: {
            ...$event.variables,
            collectionId: activeHeader.id,
          },
          criteria: $event.criteria,
        })
      " />
  </div>
</template>
<script>
import {
  mapActions, mapMutations, mapState, mapGetters,
} from 'vuex';
import HeaderLogos from '@/components/CollectionsLibrarysRelocate/Logos';
import CollectionsApi from '@/services/graphql/collections';
import LibrariesApi from '@/services/graphql/libraries';
import setAutocompletePlace from '@/mixins/setAutocompletePlace';
import LogoItems from '@/components/Listing/ListingImageItem/ListingImage';
import SetPictures from '@/mixins/SetPictures';
import ManageCollectionsActions from '@/mixins/ManageCollectionsActions';
import {
  COLLECTION, LIBRARY,
} from '@/constants/cores';
import LibrarySubHeaderActions from '@/components/Libraries/LibrarySubHeaderActions';
import { ROUTE_COMMUNITY_COLLECTION } from '@/constants';
import ShareAdvancedDialog from '@/components/CollectionsLibrarysRelocate/ShareAdvancedDialog';
import AppGoogleLocationField from '@/components/App/AppFields/AppGoogleLocationField';
import CollectionHeaderActions from '@/components/Listing/CollectionHeader/CollectionHeaderActions';
import { omit } from 'lodash';
export default {
  name: 'CollectionHeader',
  components: {
    ShareAdvancedDialog,
    LibrarySubHeaderActions,
    HeaderLogos,
    LogoItems,
    locationField: AppGoogleLocationField,
    headerActions: CollectionHeaderActions,
  },
  mixins: [
    setAutocompletePlace,
    SetPictures,
    ManageCollectionsActions,
  ],
  props: {
    viewAsMember: {
      type: Object,
      default: null,
    },
    isCommunityCollections: {
      type: Boolean,
      default: false,
    },
    isSharedCollection: {
      type: Boolean,
      default: false,
    },
    isLibrary: {
      type: Boolean,
      default: false,
    },
    activeHeader: {
      type: Object,
      default: () => ({
      }),
    },
    readMode: {
      type: Boolean,
      default: false,
    },
    renderSharingInHeader: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      COLLECTION,
      renameItem: null,
      editName: null,
      editAuthor: null,
      editLocation: null,
      editAddress: null,
      editDescription: null,
      logo: null,
      address: null,
      // to prevent error(not an instance of HTMLInputElement) in console
      initializeGoogleAutocompleteOnMounted: false,
      generalCommentsModal: false,
    };
  },
  computed: {
    ...mapState('Workspace', ['activeWorkspaceId']),
    ...mapGetters(['userId', 'premiumGroup']),
    ...mapGetters('UserRoles', [
      'canAddProductToCollection',
      'findUserRoleInLibraryForActiveHeader',
      'canModifyCollection',
      'canUpdateInfoInCustomLibrary',
    ]),
    ...mapGetters('Collections', [
      'isGroupFollowed',
    ]),
    ...mapGetters({
      mobileViewOptimizationsForTradeShow: 'FeatureFlags/mobileViewOptimizationsForTradeShow',
    }),
    renderAddress() {
      const { editLocation: location, editAddress: address } = this;
      const addressObject = {
        location,
        address,
      };
      const paramToOmit = address ? 'location' : 'address';
      return omit(addressObject, [paramToOmit]);
    },
    renderAddressText() {
      const { location, address } = this.activeHeader;
      return address?.formatted_address || location;
    },
    viewMemberLink() {
      return this.$router.resolve({
        name: 'collection-library',
        params: {
          wId: this.activeHeader?.workspaceId,
          id: this.$route.params.id,
        },
      })?.href;
    },
    canWorkWithModifyProduct() {
      if (this.activeHeader.docType == LIBRARY) {
        return this.canUpdateInfoInCustomLibrary(this.findUserRoleInLibraryForActiveHeader);
      }
      return this.canModifyCollection(this.findUserRoleInLibraryForActiveHeader);
    },
    createLibrary() {
      return ['community-collections', 'collections'].includes(
        this.$route.name
      );
    },
    disableActions() {
      return ![ROUTE_COMMUNITY_COLLECTION, 'community-collections'].includes(
        this.$route.name
      );
    },
    shortTitle() {
      if (this.activeHeader.name) {
        return this.activeHeader.name.length > 43
          ? this.activeHeader.name.slice(0, 43) + '...'
          : this.activeHeader.name;
      }
      return '';
    },
    daysToExpiredPublishLeft() {
      const expDate = this.activeHeader?.publishExpirationDate;
      if (expDate) {
        let endDate = this.$moment(new Date(Number.parseInt(expDate)));
        let now = this.$moment();
        return endDate.diff(now, 'days');
      }
      return null;
    },
  },
  watch: {
    'activeHeader.publishExpirationDate'(val) {
      if (val) this.showBunnerUnpublishedCollection();
    },
  },
  destroyed() {
    this.closeBanner();
  },
  methods: {
    ...mapMutations(['spinner', 'setBanner', 'closeBanner']),
    ...mapActions({
      updateActiveListingElement: 'updateActiveListingElement',
      manageSharedUser: 'Collections/manageSharedUser',
      updateLibraryName: 'Libraries/updateLibraryName',
      updateCollectionName: 'Collections/updateCollectionName',
      handleError: 'handleError',
      openCreateProductModal: 'Collections/openCreateProductModal',
    }),
    showBunnerUnpublishedCollection() {
      const { publishExpirationMessage } = this.activeHeader;
      if (!publishExpirationMessage) return false;
      //puting count days in text
      const message = publishExpirationMessage.replace('*', this.daysToExpiredPublishLeft);
      this.setBanner({
        value: true,
        message,
      });
    },
    edit(e) {
      this.editName = e;
    },
    editDesc(e) {
      this.editDescription = e;
    },
    rename() {
      this.renameItem = this.activeHeader;
      this.editName = this.activeHeader.name;
      this.editDescription = this.activeHeader.description;
      this.editAuthor = this.activeHeader.author;
      this.address = this.activeHeader.location;
      this.editLocation = this.activeHeader.location;
      this.editAddress = this.activeHeader.address;
      this.$nextTick(() => {
        this.$refs.editInput.focus();
      });
    },
    async saveEditedName(element) {
      if (!this.editName) {
        this.renameItem = null;
      } else {
        this.spinner(true);
        try {
          const logo =
            typeof this.logo === 'string' ? this.logo : this.activeHeader.logo;
          let variables = {
            libraryId: this.createLibrary ? element.id : element.libraryId,
            collectionId: this.createLibrary ? '' : element.id,
            name: this.editName,
            logo,
            description: this.editDescription,
          };
          const workspaceId = this.activeWorkspaceId;
          const { data } = this.createLibrary
            ? await LibrariesApi.update({
              ...variables,
              workspaceId,
            })
            : await CollectionsApi.update({
              ...variables,
              workspaceId,
              author: this.editAuthor,
              ...this.renderAddress,
            });
          this.createLibrary
            ? this.updateLibraryName(data.response)
            : this.updateCollectionName(data.response);
          this.updateActiveListingElement(data.response);
        } catch (err) {
          this.handleError(err);
        }
        this.renameItem = null;
        this.spinner(false);
      }
    },
    addLogo(item) {
      this.logo = item;
    },
    filteredMembers(members) {
      return members ? members.filter((member) => member) : [];
    },
  },
};
</script>
<style lang="scss">
@import '~vuetify/src/styles/settings/_variables';
.collection-header {
  margin-top: 10px;
  justify-self: center;
  justify-content: center;
  display: flex;
  gap: 30px;
  align-items: center;
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    flex-direction: column;
  }
  .logo-community {
    width: 160px;
  }
  .collection-container {
    max-width: 600px;
    display: grid;
    p {
      margin-bottom: 0px !important;
    }
    .v-text-field {
      height: auto !important;
      margin: 0 !important;
      padding: 0 !important;
      .v-text-field__slot {
        padding-bottom: 5px !important;
      }
    }
    .edit-input-collection-description {
      textarea {
        max-height: 104px !important;
        overflow: auto !important;
      }
    }
    &__description {
      white-space: pre-wrap;
      word-break: break-word;
    }
    &__header {
      display: flex;
      gap: 16px;
      align-items: self-end;
      .v-icon {
        cursor: pointer;
        border-bottom: 4px solid black;
      }
      &__info {
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        margin-top: 10px;
        margin-bottom: 10px;
        &__item {
          display: flex;
          column-gap: 5px;
        }
      }
    }
    .short-name {
      font-weight: bold;
      font-size: 36px;
      // white-space: pre-wrap;
      display: flex;
      justify-content: space-between;
      word-break: break-word;
    }
    span {
      color: var(--v-mainGrey-base);
      font-size: 16px;
      line-height: 19px;
    }
    .buttons-actions {
      display: flex;
      gap: 15px;
      align-items: center;
      justify-content: flex-start;
      .v-dialog__container {
        display: none !important;
      }
      @media #{map-get($display-breakpoints, 'sm-and-down')} {
        flex-wrap: wrap;
        justify-content: flex-end;
      }
      p, div, span {
        cursor: pointer;
      }
      .v-btn {
        height: auto !important;
        width: auto !important;
        cursor: pointer;
        outline: none;
        padding: 6px 22px !important;
        font-size: 16px;
        line-height: 19px;
        border-radius: 50px;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
      }
      .publish {
        background: #e6edee;
        padding: 6px 22px !important;
        font-size: 16px;
        line-height: 19px;
        border-radius: 50px;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
        .v-btn__content {
          color: var(--v-lightBlack-base);
        }
      }
    }
  }
  .logo {
    width: 160px;
    border-radius: 5px;
  }
  &.mobile-view-trade-show-container {
    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      .buttons-actions {
        justify-content: center;
      }
      .collection-container {
        &__header {
          justify-content: center;
          &__info {
            &__item {
              justify-content: center;
            }
          }
        }
        &__description {
          display: flex;
          justify-content: center;
        }
      }
    }
  }
}
.hide-border {
  border-bottom: none !important;
}
</style>
