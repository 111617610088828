var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"height":"100%"},on:{"!dragleave":function($event){return _vm.dragEnd.apply(null, arguments)},"!dragover":function($event){return _vm.dragOver.apply(null, arguments)}}},[_c('div',{staticClass:"v-card__wrapper"},[_c('file-upload',{ref:"upload",attrs:{"multiple":false,"drop":true,"drop-directory":false},model:{value:(_vm.files),callback:function ($$v) {_vm.files=$$v},expression:"files"}}),_c('DragAndDropArea',{class:{'drag-drop-container__active': _vm.drag},attrs:{"absolute":"","height":"100%","max-width":"100%","is-dialog":false,"is-drag":_vm.$refs.upload && _vm.$refs.upload.dropActive}}),_c('DragAndDropArea',{directives:[{name:"show",rawName:"v-show",value:(_vm.fileForPreview),expression:"fileForPreview"}],attrs:{"is-dialog":false,"absolute":"","max-width":"100%","height":"100%","upload-later":"","custom-metadata":{
        resourceId: _vm.resourceId,
        resourceType: _vm.resourceType,
        commentKind: _vm.commentKind,
      },"file":_vm.fileForPreview},on:{"close":function($event){_vm.fileForPreview = null},"uploadFile":_vm.createCommentAttachment}}),_c('Comments',{ref:"containerForComments",attrs:{"allow-resolve-comments":"","data-test":"row_comments_comment_block","data":{
        comments: _vm.comments,
        nextToken: _vm.nextToken,
        nextTokenForUnread: _vm.nextTokenForUnread,
      },"is-disabled-comments":_vm.isDisabledComments,"scrolled-comment":_vm.scrolledComment,"row-id":_vm.rowId,"resource-id":_vm.resourceId,"resource-type":_vm.resourceType,"edited-row-id":_vm.editedRowId,"files-src":_vm.filesSrc},on:{"update:filesSrc":function($event){_vm.filesSrc=$event},"update:files-src":function($event){_vm.filesSrc=$event},"manageCellCommentResolved":_vm.manageCellCommentResolved,"getComments":_vm.getCommentsList,"editComment":_vm.editCommentAction,"deleteComment":_vm.deleteComment}})],1),_c('v-card-actions',{staticClass:"sticky-bottom text-wrapper pt-sm-6",class:{'v-card__actions__private' : _vm.privateMode }},[_c('v-col',{staticClass:"pa-0"},[_c('CommentsForm',{ref:"commentsForm",attrs:{"resource-type":_vm.resourceType,"resource-id":_vm.resourceId,"mentioned-users":_vm.allMentionUsers,"private":_vm.privateMode,"edit-id":_vm.editedRowId,"message":_vm.message,"value-blur":_vm.valueBlur,"is-disabled-comments":_vm.isDisabledComments,"focus-message":_vm.focusMessage},on:{"update:mentionedUsers":function($event){_vm.allMentionUsers=$event},"update:mentioned-users":function($event){_vm.allMentionUsers=$event},"update:private":function($event){_vm.privateMode=$event},"update:editId":function($event){_vm.editedRowId=$event},"update:edit-id":function($event){_vm.editedRowId=$event},"update:message":function($event){_vm.message=$event},"update:valueBlur":function($event){_vm.valueBlur=$event},"update:value-blur":function($event){_vm.valueBlur=$event},"update:focusMessage":function($event){_vm.focusMessage=$event},"update:focus-message":function($event){_vm.focusMessage=$event},"editComment":_vm.editComment,"onPaste":_vm.onPaste,"createComment":_vm.createComment}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }