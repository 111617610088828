<template>
  <v-card
    height="100%"
    @dragleave.capture="dragEnd"
    @dragover.capture="dragOver">
    <div
      class="v-card__wrapper">
      <file-upload
        ref="upload"
        v-model="files"
        :multiple="false"
        :drop="true"
        :drop-directory="false" />
      <DragAndDropArea
        absolute
        height="100%"
        max-width="100%"
        :is-dialog="false"
        :is-drag="$refs.upload && $refs.upload.dropActive"
        :class="{'drag-drop-container__active': drag}" />
      <DragAndDropArea
        v-show="fileForPreview"
        :is-dialog="false"
        absolute
        max-width="100%"
        height="100%"
        upload-later
        :custom-metadata="{
          resourceId,
          resourceType,
          commentKind,
        }"
        :file="fileForPreview"
        @close="fileForPreview = null"
        @uploadFile="createCommentAttachment" />
      <Comments
        ref="containerForComments"
        allow-resolve-comments
        data-test="row_comments_comment_block"
        :data="{
          comments,
          nextToken,
          nextTokenForUnread,
        }"
        :is-disabled-comments="isDisabledComments"
        :scrolled-comment="scrolledComment"
        :row-id="rowId"
        :resource-id="resourceId"
        :resource-type="resourceType"
        :edited-row-id="editedRowId"
        :files-src.sync="filesSrc"
        @manageCellCommentResolved="manageCellCommentResolved"
        @getComments="getCommentsList"
        @editComment="editCommentAction"
        @deleteComment="deleteComment" />
    </div>
    <v-card-actions
      :class="{'v-card__actions__private' : privateMode }"
      class="sticky-bottom text-wrapper pt-sm-6">
      <v-col class="pa-0">
        <CommentsForm
          ref="commentsForm"
          :resource-type="resourceType"
          :resource-id="resourceId"
          :mentioned-users.sync="allMentionUsers"
          :private.sync="privateMode"
          :edit-id.sync="editedRowId"
          :message.sync="message"
          :value-blur.sync="valueBlur"
          :is-disabled-comments="isDisabledComments"
          :focus-message.sync="focusMessage"
          @editComment="editComment"
          @onPaste="onPaste"
          @createComment="createComment" />
      </v-col>
    </v-card-actions>
  </v-card>
</template>
<script>
import {
  COMMENT_KIND, COMMENT_PRIVACY, COMMENT_TYPE,
} from '@/constants/comments';
import CommentsApi from '@/services/graphql/comments';
import {
  mapActions, mapMutations, mapState,
} from 'vuex';
import AppCommentsForm from '@/components/App/AppComments/AppCommentsForm';
import AppCommentsList from '@/components/App/AppComments/AppCommentsList';
import PasteClipboard from '@/mixins/PasteClipboard';
import GetCommenCreatorAvatar from '@/mixins/getCommentCreatorAvatar';
import FileUpload from 'vue-upload-component';
import DragAndDropArea from '@/components/App/AppDragAndDropArea';
import CommentsResources from '@/mixins/CommentsResources';
import { Storage } from 'aws-amplify';
import { subscribeForAttachments } from '@/services/graphql/commentsSubscriptions';
import {
  orderBy, uniqBy,
} from 'lodash';
export default {
  name: 'AppCommentsCard',
  components: {
    CommentsForm: AppCommentsForm,
    Comments: AppCommentsList,
    FileUpload,
    DragAndDropArea,
  },
  mixins: [
    PasteClipboard,
    CommentsResources,
    GetCommenCreatorAvatar,
  ],
  props: {
    commentsModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      privateMode: null,
      allMentionUsers: [],
      nextToken: null,
      nextTokenForUnread: null,
      editedRowId: null,
      message: null,
      valueBlur: '',
      focusMessage: false,
      scrolledComment: null,
      fileForPreview: null,
      drag: false,
      files: [],
      filesSrc: {
      },
      rowId: null,
    };
  },
  computed: {
    ...mapState('Workspace', ['activeWorkspaceId']),
    ...mapState('Comments', ['collectionCommentsListV2', 'projectCommentsListV2', 'newProjectCommentIdV2', 'newCollectionCommentIdV2']),
    ...mapState(['avatars',
      'role', 'commentsSubscriptions',
    ]),
    privacy() {
      return this.privateMode ? COMMENT_PRIVACY.PRIVATE : COMMENT_PRIVACY.PUBLIC;
    },
    commentKind() {
      if (this.$route.query.generalComments) {
        return COMMENT_KIND.GENERAL;
      }
      return COMMENT_KIND.GENERAL;
    },
    isDisabledComments() {
      return false;
    },
    comments: {
      get() {
        const { currentResource } = this;
        return this[currentResource.list];
      },
      set(val) {
        const { currentResource } = this;
        return this[currentResource.setMutation](val);
      },
    },
  },
  watch: {
    collaborators(val) {
      val.forEach((item) => {
        if (item.picture && !this.avatars[item.picture]) {
          this.getAvatar({
            key: item.picture,
            getAvatars: true,
            context: this,
          });
        }
      });
    },
    files(val) {
      const { file } = val[val.length - 1];
      this.fileForPreview = file;
    },
    newProjectCommentIdV2: {
      handler(val) {
        if (val) {
          this.scrollToComment({
            commentId: val,
          });
        }
      },
      deep: true,
    },
    newCollectionCommentIdV2: {
      handler(val) {
        if (val) {
          this.scrollToComment({
            commentId: val,
          });
        }
      },
      deep: true,
    },
    comments: {
      async handler(val) {
        val.forEach(comment => {
          if (comment?.attachments) {
            const thumbnail = comment.attachments[0]?.thumbnails;
            const fileId = thumbnail?.small?.key || thumbnail?.large?.key;
            if (fileId && !this.filesSrc.hasOwnProperty(fileId)) {
              this.getThumbnail(fileId);
            }
            this.getAvatarsForComments(comment);
          }
        });
      },
      deep: true,
      immediate: true,
    },
    commentsModal: {
      handler(val) {
        if (val) {
          this.getCommentsList();
        }
      },
      immediate: true,
    },
  },
  created() {
    window.addEventListener('dragover', (e) => {
      e.preventDefault();
    }, false);
    window.addEventListener('drop', (e) => {
      e.preventDefault();
    }, false);
  },
  mounted() {
    const { resourceType, resourceId, commentKind } = this;
    this.subscribeComments({
      resourceId,
      resourceType,
      commentKind,
    });
    if (this.isCollectionType) {
      this.setPrivateMode({
        commentType: COMMENT_TYPE.TEXT,
        privacy: COMMENT_PRIVACY.PRIVATE,
      });
    }
  },
  beforeDestroy() {
    this.clearCommentForm();
    this.fileForPreview = null;
    this.setReadComments();
    this.comments = [];
    this.nextToken = null;
    this.nextTokenForUnread = null;
    this.removeSubscriptions([`${this.resourceType}_${this.resourceId}`]);
  },
  methods: {
    ...mapMutations('Comments', ['setCollectionCommentsV2', 'setProjectCommentsV2']),
    ...mapMutations(['spinner']),
    ...mapActions({
      setComments: 'Comments/setCommentV2',
      deleteCommentV2: 'Comments/deleteCommentV2',
      removeSubscriptions: 'ManageSubscriptions/removeSubscriptions',
      subscribeComments: 'Comments/subscribeCommentsV2',
      handleError: 'handleError',
      setSlantV2: 'Comments/setSlantV2',
      setCommentImage: 'Comments/setCommentImage',
    }),
    ...mapActions([
      'handleError',
    ]),
    async getThumbnail(id) {
      try {
        const url = await Storage.get(id, {
          customPrefix: {
            public: '',
          }, level: 'public',
        });
        this.$set(this.filesSrc, id, url);
      } catch (err) {
        console.log('err with set image', err);
      }
    },
    setReadComments() {
      const {
        resourceType,
        resourceId,
      } = this;
      const readMessageIDs = this.comments.reduce((result, option) => {
        if (!option.read) {
          return [...result, option.commentId];
        }
        return result;
      }, []);
      if (!readMessageIDs.length) {
        return;
      }
      this.setSlantV2({
        resourceType,
        resourceId,
        readMessageIDs,
      });
    },
    async manageRowComment() {
      if (this.valueBlur) {
        await this.$refs.commentsForm.manageRowComment();
      }
    },
    async createCommentAttachment({ id, file, config }) {
      try {
        await this.manageRowComment();
        const { response } = await this.createComment({
          attachments: [ file?.name ],
          commentType: COMMENT_TYPE.ATTACHMENT,
        });
        const { attachments, commentId } = response;
        if (!attachments[0]?.id) {
          throw new Error('Empty attachment');
        }
        const attachmentId = attachments[0]?.id;
        await Storage.put(id, file, {
          ...config,
          metadata: {
            ...config.metadata,
            commentId,
            attachmentId,
            filename: file?.name,
          },
        });
        subscribeForAttachments({
          commentId,
          attachmentId,
        }, ({ data }) => {
          this.setCommentImage({
            commentId,
            attachment: data.response,
            resourceType: this.resourceType,
          });
        });
        this.fileForPreview = null;
      } catch (e) {
        this.handleError(e);
      }
    },
    async scrollToComment({ commentId: id, mentionedComment = false }) {
      try {
        await this.$nextTick();
        const commentsRows = this.$refs.containerForComments.$refs.commentsRows;
        if (!commentsRows) return;
        const comment = commentsRows.find(({ id: commentId = null } = {
        }) => {
          if (!commentId) return false;
          return commentId === `commentId_${id}`;
        });
        if (!comment) return;
        comment.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
        if (mentionedComment && comment) {
          this.scrolledComment = id;
        }
      } catch (e) {
        console.log(e);
      }
    },
    dragEnd() {
      this.drag = false;
    },
    dragOver() {
      this.drag = true;
    },
    manageCellCommentResolved(comment) {
      this.spinner(true);
      this.editComment({
        ...comment,
        commentResolved: !comment.commentResolved,
      });
    },
    async createComment({ mentioned, message, attachments, commentType = COMMENT_TYPE.TEXT, resolve }) {
      try {
        const { resourceType, resourceId, activeWorkspaceId: workspaceId, commentKind, privacy } = this;
        const { data } = await CommentsApi.createComment({
          commentKind,
          privacy,
          resourceType,
          resourceId,
          workspaceId,
          message,
          commentType,
          mentioned,
          attachments,
        });
        this.clearCommentForm();
        this.setComments(data.response);
        return data;
      } catch (e) {
        console.log(e);
      } finally {
        if (resolve) {
          this.spinner(false);
          resolve();
        }
      }
    },
    async getCommentsList({ scanIndexForward = false } = {
    }) {
      try {
        const { activeWorkspaceId: workspaceId, resourceType, commentKind, resourceId, nextToken, nextTokenForUnread, mode } = this;
        const { data } = await CommentsApi.listComments({
          workspaceId,
          resourceType,
          resourceId,
          commentKind,
          scanIndexForward,
          limit: 1000,
          nextToken: scanIndexForward ? nextTokenForUnread : nextToken,
        });
        if (!data) {
          return;
        }
        const response = data.response;
        let commentsForSorting = [...this.comments, ...response.comments];
        commentsForSorting = uniqBy(commentsForSorting, 'commentId');
        this.comments = orderBy(commentsForSorting,
          ['createdDate'], ['asc']);
        if (mode) {
          this.privateMode = mode;
        }
        this.comments.forEach(comment => this.getAvatarsForComments(comment));
        if (scanIndexForward) {
          this.nextTokenForUnread = response.nextToken;
        } else {
          this.nextToken = response.nextToken;
        }
      } catch (e) {
        console.log(e);
      }
    },
    async editComment({ commentId, message, mentioned, commentResolved = false }) {
      try {
        const { activeWorkspaceId: workspaceId, resourceType, commentKind, resourceId } = this;
        const { data } = await CommentsApi.editComment({
          workspaceId,
          resourceId,
          commentKind,
          commentId,
          resourceType,
          attributes: {
            message,
            ...mentioned && {
              mentioned,
            },
            commentResolved,
          },
        });
        this.spinner(false);
        this.clearCommentForm();
        this.focusMessage = false;
        this.setComments(data.response);
      } catch (e) {
        console.log(e);
      }
    },
    /**
     * @param commentId
     * @param privacy
     * @returns {Promise<void>}
     */
    async deleteComment({ commentId, privacy }) {
      this.spinner(true);
      try {
        const { resourceId, resourceType, commentKind, activeWorkspaceId: workspaceId } = this;
        await CommentsApi.deleteComment({
          workspaceId,
          resourceId,
          commentId,
          privacy,
          resourceType,
          commentKind,
        });
        this.spinner(false);
        this.deleteCommentV2({
          commentId,
          resourceType,
        });
      } catch (err) {
        this.handleError(err);
      } finally {
        this.spinner(false);
      }
    },
    clearCommentForm() {
      this.$refs.commentsForm.clearCommentForm();
      if (this.isCollectionType) {
        this.setPrivateMode({
          commentType: COMMENT_TYPE.TEXT,
          privacy: COMMENT_PRIVACY.PRIVATE,
        });
      }
    },
    editCommentAction({ commentId, message, commentType, privacy, cellPointer, mentioned }) {
      this.focusMessage = true;
      this.message = message;
      this.editedRowId = commentId;
      this.localCellPointer = cellPointer;
      this.allMentionUsers = mentioned;
      this.setPrivateMode({
        commentType, privacy,
      });
    },
    setPrivateMode({ commentType, privacy }) {
      if (privacy === 'public') {
        this.privateMode = null;
      } else if (privacy === 'private' && commentType === 'text') {
        this.privateMode = privacy;
      } else {
        this.privateMode = commentType;
      }
    },
  },
};
</script>
<style scoped lang="scss">
@import '~vuetify/src/styles/settings/_variables';
::v-deep .v-dialog__form {
  overflow: hidden;
}
.v-card {
  display: grid;
  box-shadow: none !important;
  .drag-drop-container {
    z-index: 1000;
  }
  &__wrapper {
    overflow: auto;
    transition: 0.3s;
  }
  &__actions {
    position: relative !important;
    width: auto !important;
    align-items: flex-end;
    margin-left: 24px;
    margin-right: 24px;
    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      justify-content: inherit;
      align-items: stretch;
      margin-left: 0;
      margin-right: 0;
    }
  }
  &__actions__private {
    ::v-deep .v-input__slot {
      border: 5px solid #BAC2C3;
    }
  }
}
.text-wrapper {
  display: grid;
  align-content: end;
  transition: 0.3s;
  padding: 0px;
}
.file-uploads {
  display: none;
}
.comment-file-container {
  width: 70%;
  max-width: 320px;
  @media only screen and (max-width: 599px) {
    width: 100%;
  }
  &__content {
    min-height: 66px;
    background: #FAFAFA;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #fff;
    padding: 8px;
    &:hover {
      transition: all 0.15s ease-in;
      border-color: var(--v-lightGrey-base);
    }
    .download-p {
      a {
        z-index: 1;
      }
      span:hover {
        color: var(--v-lightBlack-base);
      }
    }
  }
}
</style>
