<template>
  <div>
    <ElementContainer
      v-for="el in dataListingToShow"
      :key="el"
      v-bind="$attrs"
      :element="el" />
  </div>
</template>
<script>
export default {
  name: 'ElementsContainer',
  components: {
    ElementContainer: () => import('./ElementContainer'),
  },
  props: {
    dataListingToShow: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
<style lang="scss">
  .type {
    .title, .information {
      font-size: 20px;
      color: var(--v-success-base);
      font-weight: bold;
    }
  }
  .description {
    .title {
      display: none;
    }
    .information {
      font-size: 16px;
      line-height: 19px;
      color: var(--v-lightBlack-base);
    }
  }
  .manufacturer, .model, .location {
    .title, .information {
      font-size: 16px!important;
      line-height: 19px;
      color: #5E6061;
    }
    .information {
      color: var(--v-lightBlack-base);
    }
  }
</style>
